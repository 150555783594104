import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HeroSection from "../components/AutomotiveDetailing/HeroSection"; // Updated path
import TestimonialsSection from "../components/TestimonialsSection";
import CallToAction from "../components/AutomotiveDetailing/CallToAction"; // Updated path
import FeaturesSection from "../components/AutomotiveDetailing/FeaturesSection"; // Updated path
import PricingPage from "./PricingPage";

const AutomotiveDetailingLandingPage = () => {
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'page_type': 'automotive_detailing_landing',
            'industry': 'automotive_detailing'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>GarageHero - Sistema para Estética Automotiva</title>
                <meta name="description"
                      content="Sistema completo para gestão de estética automotiva. Organize serviços, controle financeiro e conquiste mais clientes com o GarageHero." />
                <meta property="og:title" content="GarageHero - Sistema para Estética Automotiva" />
                <meta property="og:description"
                      content="Sistema completo para gestão de estética automotiva. Organize serviços, controle financeiro e conquiste mais clientes com o GarageHero." />
                <meta property="og:url" content="https://estetica-automotiva.garagehero.com" />
                <meta name="keywords"
                      content="sistema estética automotiva, gestão estética automotiva, software estética automotiva, agendamento estética automotiva, gestão serviços automotivos, controle financeiro automotivo" />
            </Helmet>
            <HeroSection />
            <FeaturesSection />
            <TestimonialsSection />
            <PricingPage />
            <CallToAction />
        </>
    );
};

export default AutomotiveDetailingLandingPage;
