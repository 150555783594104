import React, { useState } from 'react';
import Modal from 'react-modal';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './NewsSection.css';

const newsItems = [
  {
    image: `${process.env.PUBLIC_URL}/news-images/image-10.png`,
    title: 'SISTEMA PDV',
    date: '',
    description: 'Esta semana iniciamos a implantação do módulo de...',
    fullArticle: 'Este módulo facilita a gestão de vendas diretamente na oficina, permitindo realizar operações de venda de produtos e serviços, emissão de cupons fiscais, e integração com o controle de estoque e financeiro do sistemaAqui está o conteúdo completo do artigo sobre o Sistema PDV...'
  },
  {
    image: `${process.env.PUBLIC_URL}/news-images/Pngtree-nfs-file-document-icon-4233926-1.png`,
    title: 'Emissão de Notas Fiscais (NF) e Notas Fiscais de Serviço (NFS)',
    date: '',
    description: 'Estas funcionalidades permitem que as oficinas gerenciem...',
    fullArticle: 'Estas funcionalidades permitem que as oficinas gerenciem suas obrigações fiscais de forma eficiente e integrada ao sistema. A emissão de NF é usada para registrar a venda de produtos, enquanto a NFS é usada para a prestação de serviços.'
  },
  {
    image: `${process.env.PUBLIC_URL}/news-images/image-8.png`,
    title: 'Área do cliente',
    date: '',
    description: 'Essa funcionalidade permite que os clientes da oficina acessem...',
    fullArticle: 'Essa funcionalidade permite que os clientes da oficina acessem informações sobre seus veículos, históricos de serviços, orçamentos, e status de reparos. A área do cliente melhora a comunicação entre a oficina e os clientes, proporcionando maior transparência e comodidade.'
  },
  {
    image: `${process.env.PUBLIC_URL}/news-images/image-9.png`,
    title: 'Calendário integrado',
    date: '',
    description: 'Esse recurso ajuda na gestão de agendamentos de serviços...',
    fullArticle: 'Esse recurso ajuda na gestão de agendamentos de serviços, permitindo que a oficina organize e visualize compromissos, agendamentos de manutenção e reparos de maneira eficiente. O calendário facilita a administração do fluxo de trabalho e a gestão de atividades realizadas pela oficina.'
  },
  {
    image: `${process.env.PUBLIC_URL}/news-images/image-11.png`,
    title: 'Kanban',
    date: '',
    description: 'O kanban é uma ferramenta visual de gerenciamento de tarefas...',
    fullArticle: 'O kanban é uma ferramenta visual de gerenciamento de tarefas que permite organizar e acompanhar o progresso dos trabalhos na oficina de maneira eficiente. Com o kanban, é possível visualizar o status das ordens de serviço, identificar gargalos e melhorar a produtividade e a comunicação dentro da equipe.'
  },
  {
    image: `${process.env.PUBLIC_URL}/news-images/modelos-para-cliente-1.png`,
    title: 'Garage Card',
    date: '',
    description: 'Ele é um cartão físico oferecido pela oficina aos seus clientes...',
    fullArticle: 'Ele é um cartão físico oferecido pela oficina aos seus clientes, com o logo da oficina de um lado e um QR code no verso, que dá acesso à área do cliente do Garage Hero. Esse cartão serve como um cartão de visita e contém todo o histórico do veículo, facilitando o acesso a informações importantes de maneira prática e rápida.'
  },
  {
    image: `${process.env.PUBLIC_URL}/news-images/etiqueta-62058.png`,
    title: 'Funcionalidade de impressão de etiquetas',
    date: '',
    description: 'Especificamente para etiquetas de óleo que incluem QR codes...',
    fullArticle: 'Especificamente para etiquetas de óleo que incluem QR codes. Essa ferramenta é projetada para facilitar o acompanhamento e a gestão de serviços de troca de óleo, permitindo que informações importantes sejam acessadas de maneira rápida e eficiente pelos clientes através dos QR codes'
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const NewsSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);

  const openModal = (news) => {
    setSelectedNews(news);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedNews(null);
  };

  return (
    <section className="py-10 bg-blue-100">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold mb-6 relative">
          <div className="flex flex-col md:flex-row items-center justify-center gap-2">
            <span>ACOMPANHE AS</span>
            <span className="bg-blue-500 text-white px-3 py-1 rounded-lg">NOVIDADES!</span>
          </div>
          <a
              href="https://www.instagram.com/garageherooficial/"
              target='_blank'
              rel="noreferrer"
              className='hidden md:block absolute right-0 top-1/2 -translate-y-1/2'
          >
        <span>
            <img
                src={`${process.env.PUBLIC_URL}/Instagram.png`}
                alt="Instagram"
                className="w-8 h-8 transform hover:scale-110 transition duration-300"
            />
        </span>
          </a>
        </h2>
        <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000} keyBoardControl={true}
                  showDots={true}>
          {newsItems.map((news, index) => (
              <div key={index} className="p-4">
                <div className="p-6 rounded-lg cursor-pointer" onClick={() => openModal(news)}>
                  <img src={news.image} alt={news.title} className="rounded-lg mb-4 w-full h-64 object-fill"/>
                  <h3 className="text-lg font-semibold">{news.title}</h3>
                  <p className="text-sm text-gray-500">{news.date}</p>
                  <p className="text-sm">{news.description}</p>
                </div>
              </div>
          ))}
        </Carousel>
        <div className="mt-8 flex justify-center md:hidden">
          <a href="https://www.instagram.com/garageherooficial/" target='_blank' rel="noreferrer"
             className="bg-blue-500 text-white px-4 py-2 rounded">
            Acesse nosso Instagram
          </a>
        </div>
      </div>
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Artigo Completo"
          className="modal"
          overlayClassName="modal-overlay"
      >
        {selectedNews && (
            <div>
              <h2 className="text-2xl font-bold mb-4">{selectedNews.title}</h2>
              <p className="text-gray-500 mb-4">{selectedNews.date}</p>
              <div className="text-lg">
                {selectedNews.fullArticle}
              </div>
              <button onClick={closeModal} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">Fechar</button>
            </div>
        )}
      </Modal>
    </section>
  );
};

export default NewsSection;
