import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
      <footer className="bg-gradient-to-r from-blue-500 to-blue-600 text-white">
        <div className="container mx-auto py-12 px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-8">
            <div>
              <h3 className="text-xl font-bold mb-4">Sobre nós</h3>
              <p className="text-sm">
                Garage Hero é a solução completa para gestão de empresas do setor automotivo.
                Simplificamos seus processos e impulsionamos seu negócio.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Links Rápidos</h3>
              <ul className="space-y-2">
                <li><Link to="/funcionalidades" className="hover:text-blue-300 transition">Planos e preços</Link></li>
                <li><Link to="/central-de-ajuda" className="hover:text-blue-300 transition">Central de Ajuda</Link></li>
                <li><Link to="/politica-de-privacidade" className="hover:text-blue-300 transition">Política de Privacidade</Link></li>
                <li><Link to="/termos-de-uso" className="hover:text-blue-300 transition">Termos de Uso</Link></li>
                <li><Link to="/contato" className="hover:text-blue-300 transition">Contato</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Contato</h3>
              <p className="text-sm">
                Email: contato@garagehero.com.br<br />
                Telefone: +55 (11) 3864-4000<br />
                Endereço: Rua Lincoln Albuquerque, 259 - Cj 107/108 - Perdizes<br/>
                São Paulo - SP, 05004-010
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Siga-nos</h3>
              <div className="flex space-x-4">
                <a href="https://www.facebook.com/p/Garage-Hero-100076447021146/" target="_blank" rel="noreferrer" className="hover:text-blue-300 transition">
                  <FaFacebookF size={24} />
                </a>
                <a href="https://www.instagram.com/garageherooficial/" target="_blank" rel="noreferrer" className="hover:text-blue-300 transition">
                  <FaInstagram size={24} />
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-blue-400 flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm">&copy; {new Date().getFullYear()} Garage Hero. Todos os direitos reservados.</p>
            <div className="flex items-center mt-4 md:mt-0">
              <span className="mr-2 text-sm font-bold">#wedriveyourtech</span>
              <a href="https://motorhero.com.br/" target="_blank" rel="noreferrer">
                <img src={`${process.env.PUBLIC_URL}/Logo-Inferior-MH.png`} alt="Motor Hero Logo" className="h-16" />
              </a>
            </div>
          </div>
        </div>
      </footer>
  );
};

export default Footer;
