import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HeroSection from "../components/CarWash/HeroSection";
import TestimonialsSection from "../components/TestimonialsSection";
import CallToAction from "../components/CarWash/CallToAction";
import FeaturesSection from "../components/CarWash/FeaturesSection";
import PricingPage from "./PricingPage";

const CarWashLandingPage = () => {
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'page_type': 'car_wash_landing',
            'industry': 'car_wash'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>GarageHero - Sistema para Lava-Rápido e Estética Automotiva</title>
                <meta name="description"
                      content="Sistema completo para gestão de lava-rápido e estética automotiva. Agende serviços, controle financeiro e fidelize clientes com o GarageHero." />
                <meta property="og:title" content="GarageHero - Sistema para Lava-Rápido e Estética Automotiva" />
                <meta property="og:description"
                      content="Sistema completo para gestão de lava-rápido e estética automotiva. Agende serviços, controle financeiro e fidelize clientes com o GarageHero." />
                <meta property="og:url" content="https://lava-rapido.garagehero.com" />
                <meta name="keywords"
                      content="sistema lava-rápido, gestão lava-rápido, software lava car, sistema estética automotiva, agendamento lavagem, gestão lavagem de carros" />
            </Helmet>
            <HeroSection />
            <FeaturesSection />
            <TestimonialsSection />
            <PricingPage />
            <CallToAction />
        </>
    );
};

export default CarWashLandingPage;