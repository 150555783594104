// src/components/BenefitsSection.js
import React from 'react';

const BenefitsSection = () => {
    return (
        <section className="py-16 bg-white from-white to-blue-50">
            <div className="container mx-auto px-4">
            <h2 className="text-center text-3xl md:text-4xl font-bold mb-4">
                    <div className="flex flex-col items-center gap-2">
                        <span>PRINCIPAIS VANTAGENS E</span>
                        <div className="flex flex-wrap justify-center gap-2">
                            <span className="bg-blue-500 text-white px-3 py-1 rounded-lg">BENEFÍCIOS</span>
                        </div>
                    </div>
                </h2>
                <div className="container mx-auto text-center">
                    <img
                        src={`${process.env.PUBLIC_URL}/Vantagens-e-Beneficios_v2.png`}
                        alt="Principais Vantagens e Benefícios"
                        className="mx-auto"
                    />
                </div>
            </div>
        </section>
);
};

export default BenefitsSection;
