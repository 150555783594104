import React, { useState } from 'react';
import FlashComponent    from "../FlashComponent";
import { Menu, X } from 'lucide-react';

const HeaderCarWash = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <>
            <FlashComponent />
            <header className="bg-white shadow-md">
                <div className="container mx-auto px-4">
                    <div className="flex justify-between items-center py-4">
                        <a href="/" className="flex-shrink-0">
                            <img src={`${process.env.PUBLIC_URL}/Logo-GH-Retangular.png`} alt="GarageHero" className="h-12 sm:h-16" />
                        </a>
                        <nav className="hidden md:flex space-x-8 items-center">
                            <a href="/funcionalidades" className="text-gray-700 hover:text-blue-600 transition-colors">Planos e Preços</a>
                            <a href="/central-de-ajuda" className="text-gray-700 hover:text-blue-600 transition-colors">Central de Ajuda</a>
                            <a href="/contato" className="text-gray-700 hover:text-blue-600 transition-colors">Contato</a>
                            <a href="https://sistema.garagehero.com.br/registrar" target="_blank" rel="noopener noreferrer" className="text-blue-600 font-semibold hover:text-blue-700 transition-colors" id="header-menu-button">
                                CADASTRE-SE E TESTE GRÁTIS
                            </a>
                        </nav>
                        <button
                            className="md:hidden p-2 rounded-md text-gray-700 hover:bg-gray-100 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onClick={() => setMenuOpen(!menuOpen)}
                            aria-label="Toggle menu"
                            id="header-menu-button"
                        >
                            {menuOpen ? <X size={28} /> : <Menu size={28} />}
                        </button>
                    </div>
                </div>
                {/* Mobile menu */}
                <div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ease-in-out ${menuOpen ? 'max-h-96' : 'max-h-0'}`}>
                    <nav className="container mx-auto px-4 py-4 flex flex-col space-y-4">
                        <a href="/funcionalidades" className="text-gray-700 hover:text-blue-600 transition-colors">Planos e Preços</a>
                        <a href="/central-de-ajuda" className="text-gray-700 hover:text-blue-600 transition-colors">Central de Ajuda</a>
                        <a href="/contato" className="text-gray-700 hover:text-blue-600 transition-colors">Contato</a>
                        <a href="https://sistema.garagehero.com.br/registrar" target="_blank" rel="noopener noreferrer" className="text-blue-600 font-semibold hover:text-blue-700 transition-colors" id="header-menu-button">
                            CADASTRE-SE E TESTE GRÁTIS
                        </a>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default HeaderCarWash;