// src/pages/TermsOfUse.js
import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="container mx-auto py-10 px-4">
      <h1 className="text-3xl font-bold mb-4 mt-3">Termos de Uso</h1>

      <p className="mb-4">Prezado usuário,</p>

      <p className="mb-4">
        Obrigado por investir seu tempo para ler este documento. As informações a seguir o ajudarão a entender e operar em nossas ferramentas. É muito importante que conheça e compreenda as nossas políticas, cláusulas e condições de uso do serviço, bem como os deveres e responsabilidades que viabilizam a contratação e as restrições de direito, aqui destacadas.
      </p>

      <h2>Termo de Adesão e Condições Gerais para serviço de Processamento de Dados e Pagamento</h2>

      <p className="mb-4">
        Estes Termos e condições gerais aplicam-se ao uso dos serviços oferecidos pelo PECA PECAS SERVICOS DE INFORMACOES LTDA, empresa devidamente inscrita no CNPJ/MF sob o n° 67.351.114/0001-57, situada na Rua dos bandeirantes, 82 cj 52, Bom Retiro, São Paulo/SP, doravante nominada LICENCIANTE, por meio dos "Sites" www.pecapecas.com.br, www.motorhero.com.br, www.pecaja.com.br e www.pecaepague.com.br ("Sites"). Qualquer pessoa, doravante nominada "USUÁRIO" ou no plural "USUÁRIOS", que pretenda utilizar os serviços do LICENCIANTE deverá aceitar os Termos e condições gerais e todas as demais políticas e princípios que o regem.
      </p>

      <p className="mb-4">
        A aceitação deste Termo de Adesão e Condições Gerais é absolutamente indispensável à utilização dos Sites e serviços prestados pelo LICENCIANTE.
      </p>

      <p className="mb-4">
        O USUÁRIO deverá ler, certificar-se de haver entendido e aceitar todas as condições estabelecidas neste "Termo de Adesão e Condições Gerais" e nas "Políticas de Privacidade e Uso de Dados", assim como nos demais documentos a eles incorporados por referência, antes de seu cadastro como USUÁRIO do LICENCIANTE.
      </p>

       <h3 className="text-xl font-semibold mb-4">DISPOSIÇÕES INICIAIS:</h3>

      <p className="mb-4">
        I. O LICENCIANTE não é fornecedor de quaisquer produtos ou serviços anunciados no site. O LICENCIANTE presta um serviço consistente na oferta de uma plataforma na internet que fornece espaços para que USUÁRIOS anunciantes/potenciais vendedores anunciem, oferecendo à venda, os seus próprios produtos e serviços para que eventuais interessados na compra dos itens, os USUÁRIOS /potenciais compradores, possam negociar direta e exclusivamente entre si;
      </p>

      <p className="mb-4">
        II. Os USUÁRIOS anunciantes/potenciais vendedores somente poderão anunciar produtos ou serviços que possam vender e que tenham em estoque, estabelecendo diretamente os termos do anúncio e todas as suas características (como título, descrição e imagem dos bens, preço, categoria, quantidade, condições de venda, forma e prazo de entrega, além da forma de pagamento);
      </p>

      <p className="mb-4">
        III. Para utilizar os serviços do LICENCIANTE o USUÁRIO deve aceitar, expressamente, a "Política de Privacidade e Uso de Dados", que contém informações claras e completas sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos USUÁRIOS do LICENCIANTE. O USUÁRIO deverá efetuar um cadastro único, usando dados de cadastro (CPF/CNPJ) e senha que são de uso pessoal e intransferível. O LICENCIANTE não se responsabiliza pelo uso inadequado e divulgação destes dados para terceiros. O LICENCIANTE, nem quaisquer de seus empregados ou prepostos solicitará, por qualquer meio, físico ou eletrônico, que seja informada sua senha;
      </p>

      <p className="mb-4">
        IV. O LICENCIANTE, em razão de violação à legislação em vigor ou aos "Termo de Adesão e Condições Gerais" do LICENCIANTE, conforme a situação, poderá, sem prejuízo de outras medidas, recusar qualquer solicitação de cadastro, advertir, suspender, temporária ou definitivamente, a conta de um USUÁRIO, seus anúncios ou aplicar uma sanção que impacte negativamente sua reputação;
      </p>

      <p className="mb-4">
        V. Não é permitido anunciar produtos expressamente proibidos pela legislação vigente ou pelos "Termo de Adesão e Condições Gerais" de uso do serviço do LICENCIANTE, que não possuam a devida autorização específica de órgãos reguladores competentes, ou que violem direitos de terceiros;
      </p>

      <p className="mb-4">
        VI. O USUÁRIO/potencial comprador, antes de decidir pela compra, deverá atentar-se às informações do vendedor, ao preço, às formas de pagamento, à disponibilidade, à forma e ao prazo de entrega dos produtos e serviços. Inclusive se tem o direito de entrar em contato diretamente com vendedor através do nome, telefone e e-mail informados no anúncio;
      </p>

      <p className="mb-4">
        VII. O USUÁRIO comprador poderá exercer o direito de arrependimento dos produtos adquiridos pelos "sites", dentro do prazo de 7 dias corridos a contar do recebimento. Para isso, basta entrar em contato diretamente com o vendedor, informação esta que ficará disponível em cada negociação realizada, na lista de compras da conta do USUÁRIO.
      </p>

      <p className="mb-4">
        VIII. É vedado aos USUÁRIOS anunciar à venda ou comprar produtos que sejam proibidos ou violem a legislação vigente, bem como sejam considerados produtos proibidos pelo LICENCIANTE.
      </p>

      <p className="mb-4">
        IX. O LICENCIANTE promove e incentiva a solução amigável de controvérsias e evitar a judicialização de questões que podem ser resolvidas administrativamente, razão pela qual, os USUÁRIOS aceitam e se comprometem a utilizar as ferramentas da plataforma (reclamação/ mediação) como primeiro meio para a solução de controvérsias decorrentes das compras e vendas realizadas através do intermédio do LICENCIANTE. Os USUÁRIOS também poderão buscar a solução de controvérsias de consumo por meio do serviço www.consumidor.gov.br, mantido pela Secretaria Nacional do Consumidor (SENACON) do Ministério da Justiça e Segurança Pública, que é disponibilizado gratuitamente aos consumidores de todo o país.
      </p>

       <h3 className="text-xl font-semibold mb-4">Termo de Adesão e Condições Gerais</h3>

       <h4 className="text-xl font-semibold mb-4">1. OBJETO</h4>
      <p className="mb-4">
        Os serviços objeto dos presentes "Termo de Adesão e Condições Gerais" consistem em (i) ofertar e hospedar espaços nos "Sites" para que os USUÁRIOS anunciem à venda seus próprios produtos e (ii) viabilizar o contato direto entre USUÁRIOS vendedores e USUÁRIOS interessados em adquirir os produtos e serviços anunciados, por meio da divulgação dos dados de contato de uma parte à outra. O LICENCIANTE, portanto, possibilita aos USUÁRIOS se contatarem e negociarem entre si diretamente, sem qualquer intervenção do LICENCIANTE, na negociação ou na concretização dos negócios. Desta forma, ressalta-se que o LICENCIANTE não fornece quaisquer produtos ou serviços anunciados pelos USUÁRIOS nos "Sites".
      </p>
      <p className="mb-4">
        Para todos os fins, a compra e venda de produtos somente é formalizada após a postagem do produto pelo USUÁRIO vendedor.
      </p>

       <h4 className="text-xl font-semibold mb-4">2. CADASTRO NO SITE</h4>
      <p className="mb-4">
        Os serviços do LICENCIANTE estão disponíveis apenas para as pessoas físicas ou jurídicas que tenham capacidade legal para contratá-los.
      </p>
      <p className="mb-4">
        É proibido o cadastro de USUÁRIOS que não tenham capacidade civil (com relação a pessoas físicas) ou não sejam representantes legais (com relação a pessoas jurídicas), bem como de USUÁRIOS que tenham sido suspensos do LICENCIANTE, temporária ou definitivamente, sem prejuízo da aplicação das sanções legais previstas no Código Civil Brasileiro, notadamente, art. 166, I; 171, I e 180.
      </p>
      <p className="mb-4">
        Não poderão se registrar nas plataformas LICENCIANTE ou Mercado Pago as pessoas físicas ou jurídicas - incluindo suas subsidiárias, afiliadas, diretores, executivos, funcionários ou agentes - que sejam de propriedade ou controladas por pessoas que estejam:
      </p>
      <p className="mb-4">
        2.1. Sujeitas às Sanções (confira a definição de "Sanções", no parágrafo abaixo); ou,
      </p>
      <p className="mb-4">
        2.2. Localizadas, constituídas ou residentes em um país ou território que esteja sujeito às Sanções, ou cujo governo esteja sujeito às Sanções, incluindo, sem limitação: a região da Crimeia, Cuba, Irã, Síria e Coreia do Norte; ou,
      </p>
      <p className="mb-4">
        2.3. Aquelas que estejam incluídas nas Listas Sancionadas do OFAC (Office of Foreign Assets Control ou Escritório de Controle de Ativos Estrangeiros, dos Estados Unidos da América) e Listas de terroristas das Nações Unidas (ONU), bem como em qualquer lista nacional, serão rejeitados como USUÁRIOS ou suspensos ou desativados. de pessoas bloqueadas, sendo vedado o registro ou uso da plataforma LICENCIANTE nos referidos territórios ou jurisdições sancionadas.
      </p>

      <p className="mb-4">
        Sanções: São as restrições ou proibições sobre a capacidade de participar de negócios e outras atividades econômicas com certos países, regiões, pessoas, entidades e setores industriais ou qualquer medida governamental destinada a privar uma entidade alvo (incluindo indivíduos, empresas, territórios, países, etc.) de ativos financeiros e econômicos, a fim de neutralizar e tentar reduzir o comportamento que ameace a segurança nacional ou internacional ou viole o direito internacional.
      </p>

       <h4 className="text-xl font-semibold mb-4">3. CONFIRMAÇÃO DE CADASTRO</h4>
      <p className="mb-4">
        Apenas será confirmado o cadastramento do USUÁRIO que preencher todos os campos obrigatórios do cadastro, com informações exatas, precisas e verdadeiras. O USUÁRIO declara e assume o compromisso de atualizar os dados inseridos em seu cadastro ("Dados Pessoais") sempre que for necessário.
      </p>
      <p className="mb-4">
        Ao se cadastrar no LICENCIANTE, o USUÁRIO poderá utilizar todos os serviços disponibilizados pelas empresas do grupo ("Sites"), declarando, para tanto, ter lido, compreendido e aceitado este "Termo de Adesão e Condições Gerais".
      </p>
      <p className="mb-4">
        O USUÁRIO acessará sua conta através de dados de CNPJ/CNPJ, e-mail ou apelido (login) e senha e compromete-se a não informar a terceiros esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.
      </p>
      <p className="mb-4">
        O LICENCIANTE não se responsabiliza pela correção dos Dados Pessoais inseridos por seus USUÁRIOS. Os USUÁRIOS garantem e respondem, em qualquer caso, civil e criminalmente pela veracidade, exatidão e autenticidade, dos Dados Pessoais cadastrados.
      </p>
      <p className="mb-4">
        O apelido que o USUÁRIO utiliza no LICENCIANTE não poderá guardar semelhança com o nome do LICENCIANTE, suas marcas e nomes de domínio ou outro bem de propriedade intelectual, tampouco poderá ser utilizado qualquer apelido que insinue ou sugira que os produtos/serviços anunciados pertencem ou tenham qualquer relação com o LICENCIANTE. Também serão excluídos apelidos considerados ofensivos.
      </p>
      <p className="mb-4">
        O LICENCIANTE se reserva o direito de recusar qualquer solicitação de cadastro e de suspender um cadastro previamente aceito, que esteja em desacordo com as políticas e regras do presente "Termo de Adesão e Condições Gerais".
      </p>
      <p className="mb-4">
        Com relação ao serviço de classificados disponibilizado nos "Sites", o LICENCIANTE, a seu critério, poderá requerer um cadastro adicional aos USUÁRIOS que operarem como distribuidores ou concessionárias, como requisito para que estes tenham acesso aos pacotes de publicações especiais. Nestes casos, uma vez efetuado o cadastro adicional, os anúncios de peças feitos pelos distribuidores e concessionárias somente serão publicados no LICENCIANTE por meio da contratação de pacotes ofertados pelo LICENCIANTE ou sob outras modalidades que o LICENCIANTE venha a permitir para estes tipos de USUÁRIOS.
      </p>
      <p className="mb-4">
        O anunciante reconhece que é o único responsável pela veracidade e pelo conteúdo disponibilizado no seu anúncio, isentando o LICENCIANTE de qualquer responsabilidade nesse sentido, bem como declara, nos termos da lei, estar seu estoque de peças anunciados em plena regularidade.
      </p>
      <p className="mb-4">
        O LICENCIANTE poderá, a seu exclusivo critério, realizar as buscas que julgar necessárias para apurar dados incorretos ou inverídicos, solicitar dados adicionais e documentos que estime serem pertinentes a fim de conferir os Dados Pessoais informados e, ainda, recorrer a base de dados públicas ou privadas, podendo o USUÁRIOS editá-las caso entenda que tais dados não estejam atualizados.
      </p>
      <p className="mb-4">
        Caso o LICENCIANTE decida checar a veracidade dos dados cadastrais de um USUÁRIO e se constate haver entre eles dados incorretos ou inverídicos, poderá recorrer a dados publicamente disponíveis ou a bases de dados comerciais para alterar ou enriquecer os Dados Pessoais, ou, ainda caso o USUÁRIOS se furte ou se negue a enviar os documentos requeridos, o LICENCIANTE poderá suspender temporariamente ou definitivamente a conta, sem prejuízo de outras medidas que entender necessárias e oportunas.
      </p>
      <p className="mb-4">
        Havendo a aplicação de quaisquer das sanções acima referidas, automaticamente serão cancelados os anúncios do respectivo USUÁRIO, não lhe assistindo, por essa razão, qualquer indenização ou ressarcimento.
      </p>
      <p className="mb-4">
        O USUÁRIO compromete-se a notificar o LICENCIANTE imediatamente, e por meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem como seu acesso não autorizado por terceiros. O USUÁRIO será o único responsável pelas operações efetuadas em sua conta, uma vez que o acesso só será possível mediante a inclusão da senha, que deverá ser de conhecimento e propriedade exclusiva do USUÁRIO.
      </p>
      <p className="mb-4">
        Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta. Também não se permitirá a manutenção de mais de um cadastro por uma mesma pessoa, ou ainda a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos temporária ou definitivamente por infrações às políticas do LICENCIANTE.
      </p>

       <h4 className="text-xl font-semibold mb-4">4. MODIFICAÇÕES DO TERMO DE ADESÃO E CONDIÇÕES GERAIS</h4>
      <p className="mb-4">
        O LICENCIANTE poderá alterar, a qualquer tempo, este "Termo de Adesão e Condições Gerais", visando seu aprimoramento e melhoria dos serviços prestados. Os novos Termos e condições gerais entrarão em vigor 10 (dez) dias após sua publicação nos "Sites". No prazo de 5 (cinco) dias contados a partir da publicação da nova versão, o USUÁRIO deverá comunicar-se por e-mail caso não concorde com os termos alterados. Nesse caso, o vínculo contratual deixará de existir, desde que não haja contas ou dívidas em aberto. Não havendo manifestação no prazo estipulado, entender-se-á que o USUÁRIO aceitou o novo "Termo de Adesão e Condições Gerais" e seu uso contrato continuará vinculando as partes.
      </p>
      <p className="mb-4">
        As alterações não vigorarão em relação às negociações e anúncios já iniciados antes de sua publicação, permanecendo, nestes casos, vigente a redação anterior.
      </p>

       <h4 className="text-xl font-semibold mb-4">5. PRODUTOS ANUNCIADOS</h4>
       <h5 className="text-xl font-semibold mb-4">5.1. Anúncios/ofertas</h5>
      <p className="mb-4">
        O USUÁRIO poderá anunciar a venda de produtos em suas respectivas categorias e subcategorias. Os anúncios podem conter gráficos, textos, descrições, fotos, vídeos e outras informações relevantes do produto ou serviço oferecido, sempre que tal prática não violar nenhum dispositivo previsto em lei, neste contrato, nas demais políticas do LICENCIANTE. O produto ou serviço oferecido pelo USUÁRIO vendedor deve ser descrito com clareza, contendo todas as características relevantes. Presumir-se-á que, mediante a inclusão do anúncio no LICENCIANTE, o USUÁRIS manifesta a intenção e declara possuir o direito de vender o produto ou oferecer o serviço, além de dispor do produto para entrega imediata ou para a entrega no prazo especificado no anúncio. O USUÁRIO declara que estará disponível para prestar o serviço no prazo acordado. Os anúncios devem conter de forma destacada, caso necessário, a descrição de todos os tributos incidentes sobre a transação em estrita observância à legislação tributária aplicável. O USUÁRIOS declara também que, ao publicar um anúncio possui em estoque produtos em quantidade suficiente para cumprimento de sua oferta.
      </p>
      <p className="mb-4">
        O LICENCIANTE poderá remover aqueles anúncios cujo preço final não esteja de acordo com o "Termo de Adesão e Condições Gerais" e suas políticas, não seja suficientemente claro, ou que permita algum tipo de dúvida ou variação sobre o preço, para evitar qualquer mal entendido ou indução em erro quanto ao preço final do produto.
      </p>
      <p className="mb-4">
        O USUÁRIO deve inserir a quantidade de produtos que deseja vender e o preço por unidade. Se possuir várias unidades de um mesmo produto que não quer ou não possa vender separadamente, ou seja, um conjunto, o USUÁRIO vendedor deve inserir o preço do conjunto, estando ciente de que a tarifa de venda será cobrada pelo valor do conjunto anunciado, ainda que o USUÁRIO decida vender, posteriormente à publicação do anúncio, uma ou mais unidades, de forma isolada.
      </p>
      <p className="mb-4">
        O LICENCIANTE poderá alterar a visibilidade de anúncios cujo preço do produto e/ ou serviço não atenda a política de preço máximo estabelecida pelo LICENCIANTE, para garantir que aos seus compradores tenham acesso às melhores ofertas.
      </p>

       <h5 className="text-xl font-semibold mb-4">5.1.2 PROCESSO DE PAGAMENTO</h5>
      <p className="mb-4">
        O USUÁRIO declara e reconhece que o processamento de pagamentos depende do cumprimento de alguns procedimentos, como análise de risco e compensação bancária, a depender do meio utilizado. Em regra, os pagamentos por cartão de crédito ou dinheiro em conta do Peça&Pague são processados de forma mais rápida do que pagamentos realizados por boleto bancário. Neste sentido, o Peça&Pague não poderá ser responsável por eventuais descumprimentos de oferta feita pelo USUÁRIOS vendedor quando o processamento de pagamento ocorrer depois de esgotado o estoque do produto anunciado.
      </p>

       <h5 className="text-xl font-semibold mb-4">5.2 LICENÇA SOBRE IMAGENS, FOTOGRAFIA, MARCAS E INFORMAÇÕES DO PRODUTO</h5>
      <p className="mb-4">
        O USUÁRIO autoriza o LICENCIANTE e as empresas de seu grupo econômico a utilizar, publicar, reproduzir e/ou adaptar as imagens e fotografias incluídas em seus anúncios, seu nome comercial, marcas, frases publicitárias, logotipos, desenhos, imagens e qualquer outro sinal distintivo que identifique o USUÁRIO e seus produtos ou serviços ( "Marca") e as informações de seus produtos ("Informação do Produto"). Será obrigação do USUÁRIO incluir em seus anúncios as imagens, fotografias e a marca, bem como a informação do produto, atualizada, incluindo as advertências que sejam requeridas pela legislação em vigor para a venda ou publicidade dos produtos e serviços. Conforme o disposto nesta cláusula, o LICENCIANTE poderá obter as imagens, fotografias, marca e Informação do Produto, diretamente do USUÁRIO, por terceiros autorizados pelo USUÁRIO ou, ainda, através do site do USUÁRIO.
      </p>
      <p className="mb-4">
        O USUÁRIO outorga ao LICENCIANTE e às empresas de seu grupo econômico, uma autorização gratuita, irrevogável, não exclusiva e sem limite temporal, para usar, publicar, reproduzir e/ou adaptar as imagens, fotografias, marca e informação do produto, com a finalidade de serem usadas em todos os sites do LICENCIANTE e das empresas de seu grupo econômico, nas redes sociais e/ou em qualquer meio de comunicação, incluindo, mas não se limitando a plataformas e/ou qualquer outro meio digital ou físico que o LICENCIANTE considere oportuno ou ainda em plataformas ou sites com as quais o LICENCIANTE tenha realizado parcerias, para identificar ofertas, classificar produtos, criar catálogos, realizar ações publicitárias e de marketing vinculadas aos serviços do LICENCIANTE, incluindo a possibilidade de associação com marcas e/ou nomes comerciais de terceiros, bem como sublicenciar o uso a terceiros para os mesmos fins, incluindo autorização de uso, publicação, reprodução e adaptação de imagens, fotografias, marca e informação do produto, no âmbito de publicações de catálogo. O USUÁRIO declara e garante que é titular ou licenciado dos direitos sobre as imagens e fotografias publicadas em seus anúncios, sobre a marca, bem como sobre a informação do produto e que conta com os direitos e autorizações necessárias para conceder a autorização detalhada nesta cláusula sendo o único e exclusivo responsável por qualquer infração a direitos de terceiros ou por inconsistências ou inexatidão na informação do produto.
      </p>
      <p className="mb-4">
        O LICENCIANTE poderá excluir a publicação das imagens e fotografias, bem como remover o anúncio da plataforma, se interpretar, ao seu exclusivo critério, que a imagem incluída no respectivo anúncio não cumpre com seus termos e condições.
      </p>

       <h5 className="text-xl font-semibold mb-4">5.3 Produtos Proibidos</h5>
      <p className="mb-4">
        Poderão ser anunciados/ofertados aqueles produtos cuja venda não esteja expressamente proibida pelos "Termo de Adesão e Condições Gerais", demais políticas do LICENCIANTE, ou pela lei vigente.
      </p>
      <p className="mb-4">
        Dentre os produtos proibidos para a comercialização destacam-se mas não se limitam a: armas, munições e material explosivo, narcóticos e substâncias proibidas, flora, fauna e derivados, fogos de artifício, tabaco e produtos relacionados, produtos ou serviços para adultos, anúncios que violem propriedade intelectual, listas de e-mails com base de dados pessoais, decodificadores, documentos legais e pessoais, programas ou serviços hacker, loteria ou jogos de azar, anúncios com finalidade diferente a venda de um produto, produtos que dependem de homologação/aprovação de órgãos governamentais, transferidor de gás, seguros, produtos inseguros e similares de igual teor.
      </p>
      <p className="mb-4">
        Ao inserir um anúncio na plataforma, o anunciante declara que o produto/serviço a ser incluído na plataforma não consta da listagem de produtos proibidos conforme "Termo de Adesão e Condições Gerais"
      </p>

       <h4 className="text-xl font-semibold mb-4">6. PRIVACIDADE DA INFORMAÇÃO</h4>
      <p className="mb-4">
        Toda informação ou Dado Pessoal do USUÁRIO é armazenado em servidores ou meios magnéticos de alta segurança. Salvo com relação às informações que são publicadas nos Sites, o LICENCIANTE adotará todas as medidas possíveis para manter a confidencialidade e a segurança das informações sigilosas, porém não se responsabilizará por eventuais prejuízos que sejam decorrentes da divulgação de tais informações por parte de terceiros que utilizem as redes públicas ou a internet, subvertendo os sistemas de segurança para acessar as informações de USUÁRIOS.
      </p>
      <p className="mb-4">
        O USUÁRIO expressamente autoriza que suas informações e dados pessoais sejam compartilhados pelo LICENCIANTE com as demais empresas integrantes do grupo econômico, parceiros comerciais, autoridades e pessoas físicas ou jurídicas que aleguem ter sido lesadas por USUÁRIOS.
      </p>
      <p className="mb-4">
        Em caso de dúvidas sobre a proteção a dados pessoais ou para obter mais informações sobre os casos nos quais poderá ser quebrado o sigilo de que trata esta cláusula, por favor, consultar a página de "POLÍTICAS DE PRIVACIDADE E USO DE DADOS".
      </p>

       <h5 className="text-xl font-semibold mb-4">6.1. RESPONSABILIDADE ASSUMIDA PELOS TERCEIROS QUE RECEBEM INFORMAÇÃO PESSOAL DO LICENCIANTE</h5>
      <p className="mb-4">
        Em virtude dos acordos celebrados, o LICENCIANTE poderá disponibilizar e/ou receber as informações pessoais de terceiros.
      </p>
      <p className="mb-4">
        O USUÁRIO declara e garante que, ao receber e/ou disponibilizar informações pessoais na relação estabelecida com o LICENCIANTE, deverá atuar de acordo com a legislação em vigor e cumprir as seguintes obrigações:
      </p>
      <p className="mb-4">
        6.1.1. Tratar a informação pessoal exclusivamente em razão do objeto do acordo celebrado e conforme as indicações do LICENCIANTE, aplicando todas as medidas de segurança que forem necessárias, sejam elas técnicas e/ou operacionais.
      </p>
      <p className="mb-4">
        6.1.2. Notificar imediatamente o LICENCIANTE quando da ocorrência de qualquer incidente que tenha potencial de afetar a segurança da informação pessoal, incluindo, mas sem se limitar a (i) recebimento de qualquer solicitação de uma autoridade que tiver como objeto a divulgação de informação pessoal; e/ou (ii) ocorrência de qualquer incidente de segurança que afete a informação pessoal.
      </p>
      <p className="mb-4">
        6.1.3. Notificar o LICENCIANTE ao receber qualquer solicitação ou queixa por parte dos titulares de dados a respeito da informação pessoal, abstendo-se de contestar o titular sem a prévia aprovação, por escrito, do LICENCIANTE.
      </p>
      <p className="mb-4">
        6.1.4. Excluir e/ ou anonimizar imediatamente a Informação Pessoal em quaisquer dos seguintes casos, salvo se existir algum impedimento legal para tanto: (i) quando tenha terminado a relação jurídica com LICENCIANTE ou (ii) por instruções expressas e por escrito do LICENCIANTE.
      </p>
      <p className="mb-4">
        6.1.5. Manter o LICENCIANTE indene de qualquer demanda, denuncia, reclamação e/ou procedimento, interposto contra o terceiro pelo titular dos dados pessoais e/ou qualquer autoridade administrativa ou judicial, com o motivo de não cumprimento por ele de suas obrigações em matéria de proteção dos dados pessoais ou de qualquer outra obrigação aqui contemplada.
      </p>
      <p className="mb-4">
        6.1.6. Permitir que o LICENCIANTE solicite toda a documentação necessária para o cumprimento do dever de segurança contemplado nesta cláusula.
      </p>

       <h4 className="text-xl font-semibold mb-4">7. OBRIGAÇÕES DOS USUÁRIOS</h4>
       <h5 className="text-xl font-semibold mb-4">7.1 OBRIGAÇÕES DO USUÁRIO COMPRADOR</h5>
      <p className="mb-4">
        Os USUÁRIOS interessados em comprar produtos ou contratar serviços anunciados por vendedores no LICENCIANTE devem manifestar seu interesse durante o período de vigência estipulado no anúncio. O USUÁRIO comprador, após manifestar interesse na compra de um item anunciado por meio das ferramentas dos "Sites", obriga-se a contatar o USUÁRIO vendedor e concretizar a transação, salvo se a negociação for proibida por lei ou por estes "Termo de Adesão e Condições Gerais" ou na hipótese de o USUÁRIOS vendedor ou comprador desistirem do negócio de comum acordo.
      </p>
      <p className="mb-4">
        Na medida em que o LICENCIANTE não possui nenhum serviço de avaliação dos USUÁRIOS vendedores, cabe aos USUÁRIOS compradores avaliar a pontuação e histórico de compra do USUÁRIO vendedor, ficando livre a sua decisão de compra ou não através do serviço promovido pelo LICENCIANTE.
      </p>
      <p className="mb-4">
        O LICENCIANTE se reserva o direito de estabelecer outras regras e procedimentos adicionais para USUÁRIOS vendedores com pontuação e histórico negativos (de acordo com os "medidores de pontuação e histórico de vendas" utilizados pelo LICENCIANTE).
      </p>
      <p className="mb-4">
        Ao manifestar o interesse em algum produto, o USUÁRIO comprador obriga-se a atender às condições de venda descritas no anúncio.
      </p>
      <p className="mb-4">
        As ofertas só terão validade se realizadas nos sites do LICENCIANTE.
      </p>
      <p className="mb-4">
        Tributos: O LICENCIANTE não se responsabiliza pelas obrigações tributárias que recaiam sobre as atividades dos USUÁRIOS dos "Sites". Assim como estabelece a legislação pertinente em vigor, o USUÁRIO comprador deverá exigir nota fiscal do USUÁRIO vendedor em suas negociações, salvo se o USUÁRIO vendedor estiver realizando uma venda eventual e não se enquadre no conceito legal de comerciante/empresário quanto aos bens postos em negociação.
      </p>

       <h5 className="text-xl font-semibold mb-4">7.2. OBRIGAÇÕES DO USUÁRIOS VENDEDOR</h5>
      <p className="mb-4">
        O USUÁRIO vendedor deve ter capacidade legal para vender o produto ou serviço anunciado. Os vendedores devem possuir os registros, habilitações, permissões e/ou autorizações exigidos pela legislação em vigor para oferecer seus produtos. O USUÁRIO vendedor obriga-se a entrar em contato com o USUÁRIO comprador para efetivar a transação sempre que tenha recebido uma manifestação de interesse na compra do item anunciado ou do serviço solicitado.
      </p>
      <p className="mb-4">
        O USUÁRIO vendedor deve, em cumprimento à legislação brasileira vigente, além de demonstrar informações claras e ostensivas a respeito de quaisquer restrições à aquisição do produto ou serviço, apontar sempre em seus anúncios e ordem de serviço as características essenciais do produto ou do serviço, incluídos os riscos à saúde e à segurança dos consumidores. Deve ser igualmente apontado pelo USUÁRIO vendedor no anúncio ou ordem de serviço quaisquer despesas adicionais que devam ser pagas pelo USUÁRIO comprador para aquisição do produto ou serviço, tais como despesas de entrega ou seguros. Também deverão ser apontados por todos os USUÁRIOS vendedores enquadrados na definição legal de fornecedor, os meios adequados para que os USUÁRIOS compradores exerçam seus direitos de arrependimento em relação aos produtos oferecidos em seus anúncios ou ordens de serviço.
      </p>
      <p className="mb-4">
        Todos os USUÁRIOS vendedores deverão, obrigatoriamente, disponibilizar aos USUÁRIOS compradores a opção de utilizar Peça&Pague, que permite o gerenciamento e a efetivação de pagamentos por diversos meios, tais como cartão de crédito, boleto bancário, ou envio de dinheiro disponível na conta do Peça&Pague.
      </p>
      <p className="mb-4">
        O LICENCIANTE se reserva o direito de requerer, de acordo com os critérios que estime pertinentes, que USUÁRIOS vendedores anunciem seus produtos e serviços nos "Sites" mediante a utilização do serviço de gerenciamento de pagamento do Peça&Pague e/ou outras ferramentas disponibilizadas pelo LICENCIANTE para cobrança do bem vendido e das tarifas pela utilização dos serviços, importando eventualmente no pagamento de tarifas aplicáveis pela utilização destes.
      </p>
      <p className="mb-4">
        Quando uma negociação se concretizar, o USUÁRIO vendedor deverá, sempre que e conforme previsto, pagar ao LICENCIANTE, em contrapartida aos serviços descritos na cláusula 1, o valor correspondente a um percentual do preço anunciado. Caso o USUÁRIO vendedor não venha a concretizar a negociação com o USUÁRIO comprador e não cancele a venda no prazo definido, ainda assim ser-lhe-á cobrado um valor em contrapartida aos serviços descritos na cláusula 1. O valor corresponderá a um percentual do preço anunciado, sempre que e conforme previsto, como se a venda houvesse sido concretizada.
      </p>
      <p className="mb-4">
        O USUÁRIO vendedor que tenha uma porcentagem de vendas canceladas igual ou superior a 2,5% poderá ser advertido, suspenso e/ou inabilitado pelo LICENCIANTE. O cancelamento das ofertas de compra impactarão na sua reputação.
      </p>
      <p className="mb-4">
        Em virtude de o LICENCIANTE não figurar como parte nas negociações de compra e venda de serviços e produtos que se realizam entre os USUÁRIOS, a responsabilidade por todas as obrigações decorrentes, sejam fiscais, trabalhistas, consumeristas ou de qualquer outra natureza, será exclusivamente do USUÁRIO vendedor. Assim, o USUÁRIO vendedor declara e reconhece que na hipótese de o LICENCIANTE ou qualquer empresa do grupo vir a ser demandada judicialmente ou tenha contra ela uma reclamação dos órgãos de proteção ao consumidor, os valores relativos às condenações, acordos, despesas processuais e honorários advocatícios dispendidos pela empresa serão de responsabilidade do USUÁRIO vendedor que deu causa, autorizando, ainda, a retirada dos respectivos valores de sua conta do Peça&Pague. Da mesma forma, o USUÁRIO vendedor reconhece ser responsável se, em decorrência de seus atos ou omissões, o LICENCIANTE efetuar o pagamento ao USUÁRIO comprador de valores pagos por este último ao referido USUÁRIO vendedor, autorizando, também, a retirada destes valores de sua conta do Peça&Pague. Por não figurar como parte nas negociações de compra e venda que se realizam entre os USUÁRIOS, o LICENCIANTE também não pode obrigar o USUÁRIO vendedor a honrar sua obrigação ou efetivar a negociação.
      </p>
      <p className="mb-4">
        Tributos: o LICENCIANTE não se responsabiliza pelas obrigações de natureza tributária que incidam sobre os negócios realizados entre USUÁRIO comprador e USUÁRIO vendedor. Assim, o USUÁRIO vendedor que atue de forma contínua, nos termos da lei em vigor, responsabilizar-se-á pela integralidade das obrigações oriundas de suas atividades, notadamente pelos tributos incidentes.
      </p>
      <p className="mb-4">
        O USUÁRIO vendedor deverá ter em mente que, na medida em que atue como um fornecedor de produtos e/ou serviços de forma contínua ou eventual, sua oferta o vincula, nos termos do artigo 30 do Código de Defesa do Consumidor e do artigo 429 do Código Civil, cujo cumprimento pode ser exigido judicialmente pelo USUÁRIO comprador.
      </p>
      <p className="mb-4">
        Por razões de segurança, a conta do USUÁRIO poderá ser suspensa, a critério do LICENCIANTE, caso este suspeite de qualquer ilegitimidade, fraude ou qualquer outro ato contrário às disposições do presente "Termo de Adesão e Condições Gerais" ou ainda até a apuração e verificação de (i) questões relativas à idoneidade do USUÁRIO; (ii) legalidade das negociações realizadas; (iii) reclamações pendentes; (iv) ausência de valores na conta do Peça&Pague para arcar com reclamações encerradas negativamente contra o USUÁRIO vendedor; e/ou (v) excesso de reclamações.
      </p>
      <p className="mb-4">
        O USUÁRIO vendedor aceita que o USUÁRIO comprador exerça o direito de arrependimento dentro do prazo de 7 dias corridos, a contar da data de recebimento do produto. Da mesma forma, o USUÁRIO vendedor aceita que o USUÁRIO comprador solicite a devolução dos produtos e cancelamento da venda. Em qualquer uma das hipóteses, o USUÁRIO vendedor deverá aceitar a devolução do produto e se encarregar dos gastos envolvidos, incluindo o frete de devolução.
      </p>

       <h5 className="text-xl font-semibold mb-4">7.3 RETENÇÃO DE VALORES</h5>
      <p className="mb-4">
        O USUÁRIO autoriza o Peça&Pague, empresa do grupo LICENCIANTE responsável pela prestação do serviço de gerenciamento de pagamento, a descontar e reter, do dinheiro disponível e informado em sua conta do Peça&Pague ("Conta de Pagamento"), eventuais valores devidos em razão de pendências financeiras devidas pelo USUÁRIO em favor do LICENCIANTE.
      </p>

       <h4 className="text-xl font-semibold mb-4">8. PRÁTICAS VEDADAS</h4>
      <p className="mb-4">
        Os USUÁRIOS não poderão, entre outras atitudes previstas no "Termo de Adesão e Condições Gerais", (i) manipular os preços dos produtos anunciados; (ii) interferir nas negociações entre outros USUÁRIOS; (iii) anunciar produtos proibidos pelas políticas do LICENCIANTE e pela legislação; (iv) agredir, caluniar, injuriar, difamar, incitar o ódio e/ou promover a violência, discriminação ou menosprezo contra USUÁRIOS ou quaisquer pessoas ligadas aos serviços disponibilizados pelas empresas do grupo LICENCIANTE, em razão de sua origem racial, étnica, nacionalidade, religião, orientação sexual, sexo ou identidade de gênero, afiliação política, condição socioeconômica ou afins; (v) fazer mais de um anúncio para o mesmo produto.
      </p>
      <p className="mb-4">
        Estes tipos de comportamento poderão ser sancionados com o cancelamento do anúncio, ou com a suspensão da sua conta como USUÁRIO do LICENCIANTE, sem prejuízo das ações legais que possam ocorrer pela configuração de delitos ou contravenções ou os prejuízos civis que possam causar aos USUÁRIOS compradores, ao LICENCIANTE ou terceiros.
      </p>

       <h4 className="text-xl font-semibold mb-4">9. VIOLAÇÃO NO SISTEMA OU DA BASE DE DADOS</h4>
      <p className="mb-4">
        Não é permitida a utilização de nenhum dispositivo, software ou outro recurso que venha a interferir nas atividades e operações do LICENCIANTE, bem como nos anúncios, descrições, contas ou seus bancos de dados. Qualquer intromissão, tentativa ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas neste "Termo de Adesão e Condições Gerais", tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados.
      </p>

       <h4 className="text-xl font-semibold mb-4">10. SANÇÕES</h4>
      <p className="mb-4">
        Sem prejuízo de outras medidas cabíveis, o LICENCIANTE poderá advertir, suspender, temporária ou definitivamente, a conta de um USUÁRIO, cancelar os seus anúncios ou aplicar uma sanção que impacte negativamente em sua reputação, a qualquer tempo, iniciando as ações legais cabíveis e/ou suspendendo a prestação de seus serviços se: (i) o USUÁRIO não cumprir qualquer dispositivo deste "Termo de Adesão e Condições Gerais" e demais políticas do LICENCIANTE; (ii) se descumprir com seus deveres de USUÁRIO; (iii) se praticar atos fraudulentos ou dolosos; (iv) se não puder ser verificada a identidade do USUÁRIOS ou se qualquer informação fornecida por ele estiver incorreta; (v) se o LICENCIANTE entender que os anúncios ou qualquer atitude do USUÁRIO tenham causado algum dano a terceiros ou ao próprio LICENCIANTE ou tenham a potencialidade de assim o fazer. Nos casos de suspensão da conta do USUÁRIO, todos os anúncios ativos e/ou ofertas realizadas serão automaticamente cancelados e a informação de que USUÁRIO não pertence mais à comunidade será incluída ao lado do nome de USUÁRIO.
      </p>

       <h4 className="text-xl font-semibold mb-4">11. RESPONSABILIDADES</h4>
      <p className="mb-4">
        O LICENCIANTE se responsabiliza por eventuais danos causados aos seus USUÁRIOS por defeitos ou vícios relativos exclusivamente à prestação do seu serviço de hospedagem e veiculação de anúncios nos "Sites", desde que o LICENCIANTE tenha dado causa aos referidos defeitos ou vícios.
      </p>
      <p className="mb-4">
        O LICENCIANTE não se responsabiliza por vícios ou defeitos técnicos e/ou operacionais oriundos do sistema do USUÁRIO ou de terceiros.
      </p>
      <p className="mb-4">
        O LICENCIANTE não é o proprietário dos produtos ou prestador dos serviços anunciados pelos USUÁRIOS nos "Sites", não guarda a posse desses itens e não realiza as ofertas de venda.
      </p>
      <p className="mb-4">
        O LICENCIANTE não se responsabiliza, por conseguinte, pela existência, quantidade, qualidade, estado, integridade ou legitimidade dos produtos oferecidos, adquiridos ou alienados pelos USUÁRIOS, assim como pela capacidade para contratar dos USUÁRIOS ou pela veracidade dos dados pessoais por eles inseridos em seus cadastros. O LICENCIANTE não outorga garantia por vícios ocultos ou aparentes nas negociações entre os USUÁRIOS. Cada USUÁRIO conhece e aceita ser o único responsável pelos produtos que anuncia ou pelas ofertas que realiza.
      </p>
      <p className="mb-4">
        O LICENCIANTE não será responsável pelo efetivo cumprimento das obrigações assumidas pelos USUÁRIOS. O USUÁRIO reconhece e aceita ao realizar negociações com outros USUÁRIOS ou terceiros o faz por sua conta e risco, reconhecendo o LICENCIANTE como mero fornecedor de serviços de disponibilização de espaço virtual para anúncios de produtos e gestão de ordens de serviços ofertados por terceiros. Em nenhum caso o LICENCIANTE será responsável pelo lucro cessante ou por qualquer outro dano e/ou prejuízo que possa sofrer devido às negociações realizadas ou não realizadas por meio dos sites decorrentes da conduta de outros USUÁRIOS.
      </p>
      <p className="mb-4">
        O LICENCIANTE recomenda que toda negociação seja realizada com cautela e bom senso. O USUÁRIO deverá sopesar os riscos da negociação, levando em consideração que pode estar, eventualmente, lidando com pessoas valendo-se de identidade falsa.
      </p>
      <p className="mb-4">
        Nos casos em que um ou mais USUÁRIOS ou algum terceiro inicie qualquer tipo de reclamação ou ação legal contra outro ou mais USUÁRIOS, todos e cada um dos USUÁRIOS envolvidos nas reclamações ou ações eximem de toda responsabilidade o LICENCIANTE e os seus diretores, gerentes, empregados, agentes, operários, representantes e procuradores, observado, ainda, o estipulado na cláusula 7. Os USUÁRIOS têm um prazo de 7 dias, contados a partir da compra, para iniciar uma reclamação contra outro(s) USUÁRIO(s). Uma vez vencido esse prazo, não será possível iniciar uma reclamação através do site do LICENCIANTE.
      </p>
      <p className="mb-4">
        Para a hipótese em que um USUÁRIO comprador inicie uma reclamação em face de um USUÁRIO vendedor por meio do site do LICENCIANTE, o LICENCIANTE se reserva o direito de, a seu exclusivo critério, alterar o motivo da reclamação realizada pelo USUÁRIO comprador, quando verificado, por exemplo, divergências nas alegações dos USUÁRIOS, modalidade de envio, comprovações apresentadas pelo USUÁRIO vendedor, entre outras. A alteração do motivo da reclamação pelo LICENCIANTE importará na aplicação da medida/sanção cabível ao USUÁRIO, conforme o caso.
      </p>
      <p className="mb-4">
        Uma vez que o USUÁRIO vendedor tem a faculdade de excluir, perguntar ou impedir outros USUÁRIOS de fazer perguntas ou ofertas de compra em seus anúncios, O USUÁRIO vendedor declara, expressamente, que, caso exerça tal faculdade, se responsabilizará exclusivamente por esse ato e assumirá as consequências que dele possam advir, inclusive, ressalvando as responsabilidades decorrentes do art. 39, II da lei n° 8.078/90.
      </p>

       <h4 className="text-xl font-semibold mb-4">12. ALCANCE DOS SERVIÇOS</h4>
      <p className="mb-4">
        Este "Termo de Adesão e Condições Gerais" não geram nenhum contrato de sociedade, mandato, franquia ou relação de trabalho entre LICENCIANTE e o USUÁRIO. O USUÁRIO manifesta ciência de que o LICENCIANTE não é parte de nenhuma negociação realizada entre USUÁRIOS, nem possui controle algum sobre a existência, qualidade, segurança ou legalidade dos produtos ou serviços anunciados pelos USUÁRIOS, sobre a veracidade ou exatidão dos anúncios elaborados pelos USUÁRIOS e sobre a capacidade dos USUÁRIOS para negociar. O LICENCIANTE não pode assegurar o êxito de qualquer negociação realizada entre USUÁRIOS, tampouco verificar a identidade ou dos Dados Pessoais dos USUÁRIOS.
      </p>
      <p className="mb-4">
        O LICENCIANTE não garante a veracidade da publicação de terceiros que apareça em seu site e não será responsável pela correspondência ou contratos que o USUÁRIO realize com terceiros.
      </p>

       <h4 className="text-xl font-semibold mb-4">13. FALHAS NO SISTEMA</h4>
      <p className="mb-4">
        O LICENCIANTE não se responsabiliza por qualquer dano, prejuízo ou perda sofridos pelo USUÁRIO em razão de falhas na internet, no sistema ou no servidor utilizado pelo USUÁRIO, decorrentes de condutas de terceiros, caso fortuito ou força maior. O LICENCIANTE também não será responsável por qualquer vírus que possa atacar o equipamento do USUÁRIO em decorrência do acesso, utilização ou navegação na internet ou como consequência da transferência de dados, arquivos, imagens, textos, vídeos ou áudio.
      </p>

       <h4 className="text-xl font-semibold mb-4">14. TARIFAS E FATURAMENTO</h4>
      <p className="mb-4">
        O cadastro no LICENCIANTE é gratuito. Todavia, será cobrada uma "TARIFA DE ENRIQUECIMENTO DE DADOS" para informações dos itens (dados, arquivos, imagens e textos) quando o USUÁRIO vendedor solicitar ao LICENCIANTE como parte do serviço prestado anterior a publicação de seu produto. Além disso, será cobrada a "TARIFA DE VENDA" que somente será paga ao LICENCIANTE quando a negociação se concretizar.
      </p>
      <p className="mb-4">
        O USUÁRIO concorda em pagar ao LICENCIANTE os valores correspondentes a qualquer serviço prestado pelo LICENCIANTE em que haja uma tarifa estabelecida. O LICENCIANTE se reserva o direito de modificar, aumentar ou excluir tarifas vigentes a qualquer momento, observado o estipulado na cláusula 4 ou durante promoções e outras formas transitórias de alteração dos preços praticados. Tais alterações não vigorarão em relação às negociações e anúncios já iniciados na data em que tais alterações sejam publicadas. Para estes, o "Termo de Adesão e Condições Gerais" valerão com a redação anterior.
      </p>
      <p className="mb-4">
        O LICENCIANTE se reserva o direito de tomar as medidas judiciais e extrajudiciais pertinentes para receber os valores devidos.
      </p>

       <h4 className="text-xl font-semibold mb-4">15. SISTEMA DE OPINIÕES</h4>
      <p className="mb-4">
        O USUÁRIO comprador conta com um sistema para opinar sobre o USUÁRIO vendedor, de acordo com as negociações entre eles realizadas. Esta é uma importante ferramenta e representa a liberdade de expressão do USUÁRIO na plataforma do LICENCIANTE, podendo se manifestar sobre a relação de compra e venda ocorrida.
      </p>
      <p className="mb-4">
        O LICENCIANTE não realiza o monitoramento dos comentários e opiniões e tampouco assume obrigação de verificar a exatidão ou veracidade dos comentários, não se responsabilizando pelo seu conteúdo em decorrência das negociações realizadas ou por qualquer comentário expresso nos sites ou por qualquer outro meio, incluindo e-mail. O LICENCIANTE se reserva o direito de, a seu exclusivo critério, editar ou excluir os comentários que sejam ofensivos ou que sejam contrários a este "Termo de Adesão e Condições Gerais". Ainda, o LICENCIANTE poderá excluir as opiniões e/ou os comentários sobre um USUÁRIO em cumprimento de uma ordem judicial, administrativa ou de autoridade competente que justifique a alteração.
      </p>

       <h4 className="text-xl font-semibold mb-4">16. PROPRIEDADE INTELECTUAL E LINKS</h4>
      <p className="mb-4">
        O LICENCIANTE e/ou suas sociedades controladoras, controladas, filiais ou subsidiárias se reservam todos os direitos, incluindo os direitos de propriedade intelectual e industrial, associados com os serviços do LICENCIANTE, seus websites, os conteúdos de suas telas, programas e bases de dados, redes, códigos, desenvolvimento, software, arquitetura, hardware, conteúdo, informação, tecnologia, fases de integração, funcionalidades, domínios, arquivos que permitam ao USUÁRIO acessar e criar sua conta, ferramentas de vendas, marcas, patentes, direitos autorais, modelos industriais, nomes comerciais, dentre outros e declaram que estão protegidos pelas leis nacionais e internacionais em vigor.
      </p>
      <p className="mb-4">
        Em nenhuma hipótese o USUÁRIO terá qualquer tipo de direito sobre eles, exceto para usar o serviço do LICENCIANTE conforme previsto neste "Termo de Adesão e Condições Gerais". É proibido o uso indevido ou contrário às normativas vigentes sobre os direitos de propriedade intelectual e industrial do LICENCIANTE, bem como sua reprodução total ou parcial, salvo quando autorizado por escrito pelo LICENCIANTE.
      </p>
      <p className="mb-4">
        Os USUÁRIOS não podem comunicar que os produtos ou serviços que oferecem são patrocinados, promovidos, produzidos, oferecidos e/ou vendidos pelo LICENCIANTE e devem abster-se de realizar qualquer ato que possa causar danos, perda de reputação ou diminuição do valor relativo aos direitos de propriedade intelectual e industrial do LICENCIANTE.
      </p>
      <p className="mb-4">
        O site pode conter links para sites de terceiros. Não obstante, como o LICENCIANTE não possui controle sobre estes sites, não será responsável pelos conteúdos, materiais, ações e/ou serviços prestados por eles, tampouco por perdas e danos, diretas ou indiretas, ocasionados pela utilização de tais sites. A presença de links de outros sites não implica uma parceria, relação, aprovação ou respaldo do LICENCIANTE com tais sites e seus conteúdos.
      </p>

       <h4 className="text-xl font-semibold mb-4">17. INDENIZAÇÃO</h4>
      <p className="mb-4">
        O USUÁRIO indenizará o LICENCIANTE, suas filiais, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por outros USUÁRIOS ou terceiros decorrentes de suas atividades nos "Sites" ou por seu descumprimento do "Termo de Adesão e Condições Gerais" e demais políticas do LICENCIANTE, ou pela violação de qualquer lei ou direitos de terceiros, incluindo honorários de advogados.
      </p>

       <h4 className="text-xl font-semibold mb-4">18. ANEXOS</h4>
      <p className="mb-4">
        São parte integrante e inseparável deste "Termo de Adesão e Condições Gerais" de uso os seguintes documentos e/ou seções do LICENCIANTE incorporados por referência e respectivos serviços oferecidos pelos "Sites". Tais documentos podem ser consultados no próprio site, clicando na página correspondente citada abaixo:
      </p>
      <p className="mb-4">
        Lista de serviços realizados pelo LICENCIANTE ("Sites"):
      </p>
      <p className="mb-4">
        I. Peça Peças: Serviço de publicação (para anunciantes) e localização (para compradores) de autopeças no site www.pecapecas.com.br
      </p>
      <p className="mb-4">
        II. MotorHero: Apresentação dos serviços e funcionalidades para empresas do setor automotivo, de autopeças e reposição através do site www.motorhero.com.br
      </p>
      <p className="mb-4">
        III. Peça Já: Sistema de orçamentação e meio de pagamento para concessionárias, oficinas e distribuidores de peças pelo site www.pecaja.com.br
      </p>
      <p className="mb-4">
        IV. Peça&Pague: Serviço de meio de pagamento (máquina de cartão e link de pagamento) através de sub-adquirente com todos os protocolos (de processo, segurança e regulatório) e autorizado pelo Bacen, realizado através do site www.pecaepague.com.br
      </p>

       <h4 className="text-xl font-semibold mb-4">19. SOLUÇÃO DE CONTROVÉRSIAS</h4>
      <p className="mb-4">
        O LICENCIANTE disponibiliza, gratuitamente aos USUÁRIOS, uma estrutura para o tratamento de reclamações e oferece, sem custos, uma equipe especializada que, além de mediar as controvérsias entre comprador e vendedor, pode ao final liberar o valor existente na conta Peça&Pague ao USUÁRIO vendedor ou devolver para o USUÁRIO comprador.
      </p>
      <p className="mb-4">
        Tal estrutura visa evitar a judicialização de controvérsias que, eventualmente, surjam na plataforma e, para isso, o LICENCIANTE firmou convênios e parcerias com autoridades a fim de promover e incentivar uma solução amigável e eficiente entre o comprador e vendedor da negociação.
      </p>
      <p className="mb-4">
        Por tais razões, os USUÁRIOS comprometem-se a utilizar as ferramentas da plataforma como primeiro meio para a solução de controvérsias decorrentes das compras e vendas realizadas no site LICENCIANTE.
      </p>
      <p className="mb-4">
        Os USUÁRIOS também poderão buscar a solução de controvérsias de consumo por meio do serviço www.consumidor.gov.br, mantido pela Secretaria Nacional do Consumidor (SENACON) do Ministério da Justiça e Segurança Pública, que é disponibilizado gratuitamente aos consumidores de todo o país com o objetivo de prevenir e reduzir a quantidade de controvérsias judicializadas.
      </p>
      <p className="mb-4">
        O cumprimento das medidas descritas acima visa a efetiva solução da reclamação dos USUÁRIOS, ou, na hipótese de não ser resolvida, poderá servir como indicativo de demonstrar a resistência à sua pretensão.
      </p>

       <h4 className="text-xl font-semibold mb-4">20. LEGISLAÇÃO APLICÁVEL E FORO DE ELEIÇÃO</h4>
      <p className="mb-4">
        Todos os itens deste "Termo de Adesão e Condições Gerais" de uso são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou qualquer outro questionamento relacionado a este "Termo de Adesão e Condições Gerais" de uso, as partes concordam em se submeter ao Foro da Cidade de São Paulo - SP, com exceção de reclamações apresentadas por USUÁRIO que se enquadrem no conceito legal de consumidores, que poderão submeter tais reclamações ao foro de seu domicílio.
      </p>

       <h4 className="text-xl font-semibold mb-4">21. CONTATO E ATENDIMENTO</h4>
      <p className="mb-4">
        Todas as requisições, correspondências, notificações ou pedidos de informação deverão ser enviadas para o endereço eletrônico do respectivo serviço:
      </p>
      <p className="mb-4">
        I. Peça Peças: contato@pecapecas.com.br
      </p>
      <p className="mb-4">
        II. MotorHero: contato@motorhero.com.br
      </p>
      <p className="mb-4">
        III. Peça Já: contato@pecaja.com.br
      </p>
      <p className="mb-4">
        IV. Peça&Pague: contato@pecaepague.com.br
      </p>
    </div>
  );
};

export default TermsOfUse;
