import React from 'react';
import {Button} from "./Button";

const RepresentativeSection = () => {
    return (
        <section className="py-16 bg-gradient-to-br from-white to-blue-50">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
                    SEJA UM <span
                    className="bg-blue-500 text-white px-3 py-1 rounded-lg inline-block mt-2">REPRESENTANTE</span> GARAGE
                    HERO!
                </h2>
                <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                    <div className="w-full md:w-1/2 mb-8 md:mb-0 flex justify-center md:justify-end">
                        <img
                            src={`${process.env.PUBLIC_URL}/Seja-um-representante.png`}
                            alt="Seja um Representante Garage Hero!"
                            className="rounded-lg shadow-xl max-w-xs md:max-w-sm lg:max-w-md"
                        />
                    </div>
                    <div className="w-full md:w-1/2 space-y-6">
                        <p className="text-lg">
                            Se você tem <strong>experiência no setor</strong> e conhece algumas empresas que podem se
                            interessar pelo sistema GarageHero, fale com nosso time e avalie ser nosso <strong>representante
                            regional</strong>.
                        </p>
                        <p className="text-lg font-semibold">
                            Relacionamento e <strong className="text-blue-600">renda extra</strong> para você!
                        </p>
                        <Button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg transition duration-300"
                            onClick={() => window.open("https://wa.me/5511934099298?text=Ol%C3%A1%2C%20estou%20no%20site%20do%20GarageHero%20e%20desejo%20ajuda", "_blank")}
                        >
                            Fale Conosco pelo WhatsApp
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RepresentativeSection;