// src/pages/ContactPage.js
import React from 'react';
import { FaEnvelope, FaPhone, FaWhatsapp, FaMapMarkerAlt } from 'react-icons/fa';

const ContactPage = () => {
  const whatsappNumber = '+5511934099298';
  const message = 'Olá, gostaria de saber mais informações.';

  const handleWhatsAppClick = () => {
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="container mx-auto py-12 px-4">
      <h1 className="text-4xl font-bold mb-8 bg-blue-500 text-white px-2 rounded-[8px] py-3 text-center mt-8 rounded-lg">Fale Conosco</h1>
      <div className="flex flex-col md:flex-row gap-8">
        <div className="bg-white shadow-lg rounded-lg p-8 w-full md:max-w-md lg:max-w-lg mx-auto mb-8">
          <h2 className="text-2xl font-semibold mb-6 text-center text-gray-700">Informações de Contato</h2>
          <div className="flex items-center mb-4">
            <FaEnvelope className="text-blue-500 mr-3" />
            <p className="text-lg text-gray-600"><strong>Email:</strong> <a href="mailto:contato@garagehero.com.br">contato@garagehero.com.br</a></p>
          </div>
          <div className="flex items-center mb-4">
            <FaPhone className="text-blue-500 mr-3" />
            <p className="text-lg text-gray-600"><strong>Telefone:</strong><a href="tel: +55 (11) 3864-4000"> +55 (11) 3864-4000</a></p>
          </div>
          <div className="flex items-center mb-4">
            <FaWhatsapp className="text-green-500 mr-3" />
            <p className="text-lg text-gray-600"><strong>WhatsApp:</strong> +55 (11) 93409-9298</p>
          </div>
          <div className="flex items-center mb-8">
            <FaMapMarkerAlt className="text-blue-500 mr-3" />
            <p className="text-lg text-gray-600"><strong>Endereço Escritório:</strong> Rua Lincoln Albuquerque, 259 - Cj 107/108 - Perdizes - São Paulo/SP</p>
          </div>
          <button
            onClick={handleWhatsAppClick}
            className="w-full bg-green-500 text-white py-3 px-6 rounded-md shadow-lg hover:bg-green-600 transition duration-300 flex items-center justify-center"
          >
            <FaWhatsapp className="mr-2" />
            Fale pelo WhatsApp
          </button>
        </div>

        <div className="w-full h-64 md:h-auto md:flex-1">
          <iframe
            title="Localização do Escritório"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.472148648145!2d-46.67983548502024!3d-23.586267084669465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59813d5dd711%3A0xa098a60d057c4e0d!2sRua%20Lincoln%20Albuquerque%2C%20259%20-%20Perdizes%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005029-010%2C%20Brasil!5e0!3m2!1spt-BR!2sus!4v1652879349456!5m2!1spt-BR!2sus"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            className="rounded-lg shadow-lg"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
