import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const carouselItems = [
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/1.png`} alt="" />
    ),
    text: "Tela inicial completa com todos os gráficos e informações simplificadas das atividades da empresa, como um <strong>Dashboard.</strong>"
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/2.png`} alt="" />
    ),
    text: "Cadastre seus clientes, veículos, fornecedores, peças e serviços. Dados completos melhoram a sua <strong>experiência</strong> e de seus parceiros de negócio."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/3.png`} alt="" />
    ),
    text: "Pesquise as placas dos veículos via <strong>integração no DENATRAN</strong> e tenha dados completos de chassi, número do motor e outros dados."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/4.png`} alt="" />
    ),
    text: "Abra suas <strong>Ordens de Serviço</strong> com o maior detalhamento possível, identificando o dia/hora de abertura, técnico e local do veículo."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/5.png`} alt="" />
    ),
    text: "Especifique no orçamento ao cliente todos os <strong>produtos e serviços</strong> que precisam ser realizados no veículo para solicitar aprovação."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/6.png`} alt="" />
    ),
    text: "<strong>Faça ckecklist com imagens e textos</strong> vinculados a OS para dar mais segurança e controle na sua operação."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/7.png`} alt="" />
    ),
    text: "Envie o orçamento para seu cliente via <strong>WhatsApp</strong>. Além disso, tenha opção de envio por email ou arquivo para impressão."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/8.png`} alt="" />
    ),
    text: "Seu cliente aprova a ordem de serviço direto da  <strong>área do cliente</strong> e passa a acompanhar o status da OS, histórico e próximos retornos."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/9.png`} alt="" />
    ),
    text: "Procure por peças genuínas através do <strong>código partnumber</strong> e receba online informação de mais de 800 concessionárias de todo Brasil."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/10.png`} alt="" />
    ),
    text: "No momento de inserir por peças e serviços em sua OS, tenha claro a <strong>margem e ganho</strong> de cada item, auxiliando no resultado."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/11.png`} alt="" />
    ),
    text: "Cadastre por <strong>agendamentos e lembretes</strong> futuros de OS como uma forma de melhor se relacionar com o cliente."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/12.png`} alt="" />
    ),
    text: "Acompanhe a evolução de todas as OSs da sua oficina através da <strong>tela Kanban</strong> com atualização online do progresso dos serviços."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/13.png`} alt="" />
    ),
    text: "<strong>Diversos relatórios</strong> de resultados, entradas, despesas e controle de conta corrente. Acompanhe a visita de clientes e resultado por OS."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/14.png`} alt="" />
    ),
    text: "Acompanhe e controle o <strong>histórico de todos registros</strong> dos serviços realizados e informação de quem atualizou, editou ou avançou cada etapa da OS."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/15.png`} alt="" />
    ),
    text: "<strong>Receba online</strong> por suas vendas e OSs, direto da área do cliente, via link de pagamento e PIX. Facilite o seu recebimento e sua conciliação."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/16.png`} alt="" />
    ),
    text: "Amplie <strong>o relacionamento com seu cliente</strong> via CRM com alertas de lembrete, agendamento, retorno e aviso de aniversário."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/17.png`} alt="" />
    ),
    text: "<strong>Conceda acesso à ferramenta</strong> para seus sócios e colaboradores. Controle o perfil de cada profissional, restringindo as funções necessárias de cada um."
  },
  {
    icon: (
        <img src={`${process.env.PUBLIC_URL}/carousel-images/18.png`} alt="" />
    ),
    text: "Se impressione com todas as nossas funcionalidades. São <strong>mais de 250 funções</strong> específicas para contribuir com o crescimento do seu negócio!"
  }
];


const CarouselSection = () => {
  return (
    <section className="bg-white py-10">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-6">
          <div className="flex flex-col md:flex-row items-center justify-center gap-2">
            <span>SISTEMA DE GESTÃO ERP</span>
            <span className="bg-blue-500 text-white px-3 py-1 rounded-lg">COMPLETO</span>
          </div>
        </h2>
        <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            showDots={false}
            arrows={true}
            className="max-w-5xl mx-auto"
        >
          {carouselItems.map((item, index) => (
              <div key={index} className="flex flex-col items-center text-center px-6 gap-8">
                {item.icon}
                <p className="text-1xl" dangerouslySetInnerHTML={{__html: item.text}}></p>
              </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default CarouselSection;
