import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {db} from '../firebase';
import {collection, getDocs} from 'firebase/firestore';
import {categories} from '../interfaces/categories';
import {FaSearch, FaShareAlt, FaBookOpen, FaTimes} from 'react-icons/fa';
import {motion, AnimatePresence} from 'framer-motion';

const HelpCenter = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const category = params.get('category');
        if (category && categories.includes(category)) {
            setSelectedCategory(category);
        }

        const fetchArticles = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'articles'));
                const fetchedArticles = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                fetchedArticles.sort((a, b) => a.title.localeCompare(b.title));

                setArticles(fetchedArticles);
            } catch (error) {
                setError('Erro ao buscar artigos');
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, [location]);

    const filteredArticles = articles.filter(
        (article) =>
            (!selectedCategory || article.category === selectedCategory) &&
            (article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                article.content.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const getArticleLink = (articleId) => {
        return `${window.location.origin}/central-de-ajuda/artigo/${articleId}`;
    };

    const getCategoryLink = (category) => {
        return `${window.location.origin}/central-de-ajuda?category=${encodeURIComponent(
            category
        )}`;
    };

    const copyToClipboard = (link, successMessage) => {
        navigator.clipboard.writeText(link).then(
            () => {
                setNotification(successMessage);
                setTimeout(() => setNotification(null), 3000);
            },
            () => {
                setNotification('Falha ao copiar o link. Por favor, tente novamente.');
                setTimeout(() => setNotification(null), 3000);
            }
        );
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        navigate(
            `/central-de-ajuda${category ? `?category=${encodeURIComponent(category)}` : ''}`
        );
    };

    return (
        <section className="bg-gray-50 from-blue-50 to-indigo-100 min-h-screen py-20 px-4 sm:px-6 lg:px-8">
            <AnimatePresence>
                {notification && (
                    <motion.div
                        initial={{opacity: 0, y: -50}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: -50}}
                        className="fixed bottom-4 left-4 bg-green-500 text-white p-4 rounded-lg shadow-lg flex items-center"
                    >
                        <span>{notification}</span>
                        <button onClick={() => setNotification(null)} className="ml-3 text-white">
                            <FaTimes/>
                        </button>
                    </motion.div>
                )}
            </AnimatePresence>
            <div className="container mx-auto">
                <h1 className="text-4xl font-bold text-center mb-12 text-indigo-800">
                    Central de Ajuda
                </h1>
                <div className="flex flex-col lg:flex-row gap-8">
                    <aside className="w-full lg:w-1/4">
                        <motion.div
                            initial={{opacity: 0, x: -50}}
                            animate={{opacity: 1, x: 0}}
                            transition={{duration: 0.5}}
                            className="bg-white p-6 rounded-xl shadow-lg"
                        >
                            <h2 className="text-2xl font-semibold mb-6 text-indigo-700">Categorias</h2>
                            <nav>
                                <ul className="space-y-3">
                                    {categories.map((category, index) => (
                                        <li key={index} className="flex justify-between items-center">
                                            <button
                                                className={`text-left w-full py-2 px-3 rounded-lg transition-colors duration-200 ${
                                                    selectedCategory === category
                                                        ? 'bg-indigo-100 text-indigo-700 font-semibold'
                                                        : 'text-gray-600 hover:bg-indigo-50'
                                                }`}
                                                onClick={() => handleCategoryChange(category)}
                                            >
                                                {category}
                                            </button>
                                            <button
                                                onClick={() =>
                                                    copyToClipboard(
                                                        getCategoryLink(category),
                                                        `Link da categoria "${category}" copiado!`
                                                    )
                                                }
                                                className="ml-2 text-indigo-500 hover:text-indigo-700 transition-colors duration-200"
                                                title="Compartilhar categoria"
                                            >
                                                <FaShareAlt/>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </motion.div>
                    </aside>
                    <main className="w-full lg:w-3/4">
                        <motion.div
                            initial={{opacity: 0, y: 50}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.5}}
                            className="bg-white p-8 rounded-xl shadow-lg"
                        >
                            <div className="relative mb-8">
                                <input
                                    type="text"
                                    placeholder="Pesquisar artigos..."
                                    className="w-full p-4 pl-12 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400"/>
                            </div>
                            {loading ? (
                                <div className="flex justify-center items-center h-64">
                                    <div
                                        className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500"></div>
                                </div>
                            ) : error ? (
                                <p className="text-red-500 text-center">{error}</p>
                            ) : (
                                <motion.div layout className="space-y-6">
                                    <AnimatePresence>
                                        {filteredArticles.length > 0 ? (
                                            filteredArticles.map((article) => (
                                                <motion.div
                                                    key={article.id}
                                                    initial={{opacity: 0, y: 20}}
                                                    animate={{opacity: 1, y: 0}}
                                                    exit={{opacity: 0, y: -20}}
                                                    className="bg-gradient-to-r from-indigo-50 to-blue-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                                                >
                                                    <Link to={getArticleLink(article.id)}>
                                                        <h3 className="text-xl font-semibold mb-2 text-indigo-700">
                                                            {article.title}
                                                        </h3>
                                                    </Link>
                                                    <p className="text-gray-600 mb-4">
                                                        Categoria: {article.category}
                                                    </p>
                                                    <div className="flex space-x-4">
                                                        <Link
                                                            to={getArticleLink(article.id)}
                                                            className="flex items-center text-indigo-600 hover:text-indigo-800 transition-colors duration-200"
                                                        >
                                                            <FaBookOpen className="mr-2"/>
                                                            Ler mais
                                                        </Link>
                                                        <button
                                                            onClick={() =>
                                                                copyToClipboard(
                                                                    getArticleLink(article.id),
                                                                    'Link do artigo copiado!'
                                                                )
                                                            }
                                                            className="flex items-center text-green-600 hover:text-green-800 transition-colors duration-200"
                                                        >
                                                            <FaShareAlt className="mr-2"/>
                                                            Compartilhar
                                                        </button>
                                                    </div>
                                                </motion.div>
                                            ))
                                        ) : (
                                            <motion.p
                                                initial={{opacity: 0}}
                                                animate={{opacity: 1}}
                                                className="text-center text-gray-500"
                                            >
                                                Nenhum artigo encontrado
                                            </motion.p>
                                        )}
                                    </AnimatePresence>
                                </motion.div>
                            )}
                        </motion.div>
                    </main>
                </div>
            </div>
        </section>
    );
};

export default HelpCenter;
