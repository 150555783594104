import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Card, CardContent } from "./Card";


const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const carouselItems = [
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Oficinas_Mec_nicas___Auto_Centers.png`, title: 'Oficinas Mecânicas & Auto Centers' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Auto_Pe_as___Acess_rios.jpg`, title: 'Auto Peças & Acessórios' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Servi_os_R_pidos.png`, title: 'Serviços Rápidos' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Funilaria_e_Pintura.png`, title: 'Funilaria e Pintura' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Loja_de_Pneus.png`, title: 'Loja de Pneus' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Borracharia.png`, title: 'Borracharia' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Est_tica_Automotiva.png`, title: 'Estética Automotiva' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Lava_r_pido.png`, title: 'Lava-rápido' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Estacionamentos.png`, title: 'Estacionamentos' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Blindadoras.png`, title: 'Blindadoras' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Concession_rias.jpg`, title: 'Concessionárias' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Revenda_de_ve_culos.png`, title: 'Revenda de veículos' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Locadoras_e_Frotistas.png`, title: 'Locadoras e Frotistas' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Despachantes.png`, title: 'Despachantes' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Moto_Center.png`, title: 'Moto Center' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Ret_ficas.png`, title: 'Retíficas' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Desmanches_Regulares.png`, title: 'Desmanches Regulares' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Marinas_e_Aeroclubes.png`, title: 'Marinas e Aeroclubes' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/M_quinas_e_Equipamentos_Agr_colas.png`, title: 'Máquinas e Equipamentos Agrícolas' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Servi_os_Manuais.png`, title: 'Serviços Manuais' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Assist_ncia_T_cnica.png`, title: 'Assistência Técnica' },
  { image: `${process.env.PUBLIC_URL}/companies-profile-section/Outros_Servi_os_Especializados.png`, title: 'Outros Serviços Especializados' },
];



const CompaniesProfileSection = () => {
  return (
      <section className="bg-gradient-to-br from-blue-100 to-white py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-center mb-12">
            PERFIL DE EMPRESAS QUE{' '}
            <span className="bg-blue-500 text-white px-3 py-1 rounded-lg inline-block mt-2">
            ATENDEMOS
          </span>
          </h2>
          <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all .5s"
              transitionDuration={500}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
          >
            {carouselItems.map((item, index) => (
                <div key={index} className="px-2">
                  <Card className="overflow-hidden transition-all duration-300 hover:shadow-lg">
                    <CardContent className="p-0">
                      <img
                          src={item.image}
                          alt={item.title}
                          className="w-full h-48 object-cover"
                      />
                      <div className="p-4">
                        <h3 className="text-lg font-semibold text-center">{item.title}</h3>
                      </div>
                    </CardContent>
                  </Card>
                </div>
            ))}
          </Carousel>
        </div>
      </section>
  );
};

export default CompaniesProfileSection;