import React from 'react';
import { Button } from "./Button";

const HeroSection = () => {
  return (
      <section className="bg-gradient-to-br from-blue-100 to-white py-8 md:py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="w-full lg:w-1/2 mb-8 lg:mb-0 text-center lg:text-left">
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-800 mb-4 leading-tight">
                O SISTEMA DE GESTÃO{' '}
                <span className="bg-blue-500 text-white px-2 py-1 rounded-lg inline-block mt-2">
                MAIS MODERNO
              </span>{' '}
                DO SETOR AUTOMOTIVO.
              </h1>
              <p className="text-gray-700 mb-6 text-base sm:text-lg">
                Teste agora e surpreenda-se como podemos elevar a sua experiência na gestão de sua empresa
              </p>
              <Button
                  className="w-full sm:w-auto bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg text-lg transition duration-300 mb-6"
                  onClick={() => window.open('https://sistema.garagehero.com.br/registrar', '_blank')}
              >
                Comece Seu Teste Grátis
              </Button>
              <div className="flex flex-wrap justify-center lg:justify-start gap-4 mb-8 lg:mb-0">
                <FeatureItem
                    icon={`${process.env.PUBLIC_URL}/Topo-Estrela.png`}
                    text="Excelência"
                />
                <FeatureItem
                    icon={`${process.env.PUBLIC_URL}/Topo-Seguran-a.png`}
                    text="Segurança"
                />
                <FeatureItem
                    icon={`${process.env.PUBLIC_URL}/Topo-Rapidez.png`}
                    text="Rapidez"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 flex justify-center lg:justify-end">
              <img
                  src={`${process.env.PUBLIC_URL}Banner-Superior-apenas-imagem-1.png`}
                  alt="GarageHero em diversos dispositivos"
                  className="max-w-full h-auto object-contain lg:max-w-md xl:max-w-lg"
              />
            </div>
          </div>
        </div>
      </section>
  );
}

const FeatureItem = ({ icon, text }) => (
    <div className="flex items-center space-x-2">
      <img src={icon} alt={text} className="w-6 h-6 sm:w-8 sm:h-8" />
      <span className="text-gray-700 font-medium text-sm sm:text-base">{text}</span>
    </div>
);

export default HeroSection;