import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FaArrowLeft, FaSpinner } from 'react-icons/fa';
import { motion } from 'framer-motion';
import DOMPurify from 'dompurify';

const ArticlePage = () => {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const docRef = doc(db, 'articles', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setArticle({ id: docSnap.id, ...docSnap.data() });
                } else {
                    setError('Artigo não encontrado');
                }
            } catch (err) {
                setError('Erro ao buscar o artigo');
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
        window.scrollTo(0, 0);
    }, [id]);

    const sanitizeHTML = (html) => {
        return {
            __html: DOMPurify.sanitize(html, { ADD_TAGS: ['iframe'], ADD_ATTR: ['allowfullscreen', 'frameborder', 'src'] })
        };
    };

    if (loading) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex items-center justify-center min-h-screen bg-gray-50 from-blue-50 to-indigo-100"
            >
                <div className="text-center">
                    <FaSpinner className="animate-spin text-5xl text-indigo-600 mx-auto mb-4" />
                    <p className="text-xl font-semibold text-indigo-800">Carregando artigo...</p>
                </div>
            </motion.div>
        );
    }

    if (error) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex items-center justify-center min-h-screen bg-gray-50 from-blue-50 to-indigo-100"
            >
                <div className="text-center bg-white p-8 rounded-xl shadow-lg">
                    <div className="text-red-500 text-5xl mb-4">⚠️</div>
                    <h1 className="text-2xl font-bold text-red-600 mb-4">{error}</h1>
                    <Link to="/central-de-ajuda" className="text-indigo-600 hover:text-indigo-800 transition-colors duration-200 flex items-center justify-center">
                        <FaArrowLeft className="mr-2" />
                        Voltar para a Central de Ajuda
                    </Link>
                </div>
            </motion.div>
        );
    }

    if (!article) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-gray-50 from-blue-50 to-indigo-100 min-h-screen py-12"
        >
            <div className="container mx-auto px-4">
                <motion.div
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="bg-white shadow-lg rounded-xl overflow-hidden"
                >
                    <div className="p-6 md:p-8">
                        <Link to="/central-de-ajuda" className="flex items-center text-indigo-600 hover:text-indigo-800 transition-colors duration-200 mb-6">
                            <FaArrowLeft className="mr-2" />
                            Voltar para a Central de Ajuda
                        </Link>
                        <h1 className="text-3xl md:text-4xl font-bold mb-2 text-indigo-900">{article.title}</h1>
                        <p className="text-gray-600 mb-6">Categoria: {article.category}</p>
                        <div className="prose max-w-none" dangerouslySetInnerHTML={sanitizeHTML(article.content)} />
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default ArticlePage;