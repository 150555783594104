import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const Consent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      const timer = setTimeout(() => setShowBanner(true), 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleAccept = () => {
    setIsClosing(true);
    setTimeout(() => {
      localStorage.setItem('cookieConsent', 'true');
      setShowBanner(false);
      window.dispatchEvent(new Event('cookieConsentAccepted'));
    }, 300);
  };

  if (!showBanner) return null;

  return (
      <div className={`fixed bottom-0 left-0 right-16 transform transition-transform duration-300 ease-out z-[999] ${isClosing ? 'translate-y-full' : 'translate-y-0'}`}>
        <div className="max-w-[1400px] mx-auto px-4 mb-4">
          <div className="bg-white rounded-xl shadow-2xl border border-gray-100 p-4 sm:p-6">
            <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
              <div className="flex items-center gap-4 text-gray-600">
                {/* Cookie Icon */}
                <div className="hidden sm:flex h-12 w-12 rounded-full bg-blue-50 items-center justify-center flex-shrink-0">
                  <svg className="w-6 h-6 text-blue-500" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M21.598 11.064a1.006 1.006 0 0 0-.854-.172A2.938 2.938 0 0 1 20 11c-1.654 0-3-1.346-3-3 0-.217.031-.444.099-.7a1.004 1.004 0 0 0-.172-.854 1.005 1.005 0 0 0-.789-.387A2.938 2.938 0 0 1 15 6c-1.654 0-3-1.346-3-3 0-.217.031-.444.099-.7a1.001 1.001 0 0 0-.961-1.262C10.766 1.038 10.384 1 10 1 4.477 1 0 5.477 0 11s4.477 10 10 10 10-4.477 10-10c0-.217-.031-.444-.099-.7a1.008 1.008 0 0 0-.303-.236zM10 19c-4.411 0-8-3.589-8-8s3.589-8 8-8a7.962 7.962 0 0 1 6.352 3.164A4.947 4.947 0 0 0 15 8c0 2.757 2.243 5 5 5a4.947 4.947 0 0 0 1.836-.352A7.962 7.962 0 0 1 10 19z"/>
                    <circle cx="10.5" cy="10.5" r="1.5"/>
                    <circle cx="14.5" cy="13.5" r="1.5"/>
                    <circle cx="7.5" cy="14.5" r="1.5"/>
                  </svg>
                </div>

                <div className="text-center sm:text-left">
                  <p className="text-gray-900 font-medium mb-1">Nós valorizamos sua privacidade</p>
                  <p className="text-sm">
                    Utilizamos cookies para melhorar sua experiência e fornecer serviços mais relevantes.
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <button
                    onClick={handleAccept}
                    className="bg-gradient-to-r from-blue-600 to-blue-500 text-white px-6 py-2.5 rounded-lg
                         font-medium hover:shadow-lg hover:shadow-blue-200 transition-all duration-300
                         hover:-translate-y-0.5"
                >
                  Aceitar e Continuar
                </button>

                <button
                    onClick={handleAccept}
                    className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                    aria-label="Fechar"
                >
                  <X className="w-5 h-5 text-gray-500" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

const FloatingWhatsAppButton = () => {
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      setShowButton(true);
    }

    const handleCookieConsent = () => setShowButton(true);
    window.addEventListener('cookieConsentAccepted', handleCookieConsent);

    return () => {
      window.removeEventListener('cookieConsentAccepted', handleCookieConsent);
    };
  }, []);

  return (
      <a
          href="https://wa.me/5511934099298?text=Ol%C3%A1%2C%20estou%20no%20site%20do%20GarageHero%20e%20desejo%20ajuda"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-4 right-4 z-[1000]"
      >
        <img
            src={`${process.env.PUBLIC_URL}/whatsapp.png`}
            alt="WhatsApp"
            className="h-12 w-12 transform hover:scale-110 transition duration-300"
        />
      </a>
  );
};

export { Consent, FloatingWhatsAppButton };