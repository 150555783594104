import React from 'react';

const FlashComponent = () => {
    return (
        <div className="bg-blue-900 text-white text-center py-2 text-sm fixed w-full top-0 z-[60] shadow-md">
            <div className="container mx-auto px-4">
                TESTE GRÁTIS POR 14 DIAS {'>'} SEM CARTÃO, SEM FIDELIDADE!
            </div>
        </div>
    );
};

export default FlashComponent;