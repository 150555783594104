import React, { useState } from 'react';
import { CheckCircle, Star, Plus } from 'lucide-react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";
import { Button } from "./ui/button";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: { max: 1024, min: 640 },
        items: 1,
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 640, min: 0 },
        items: 1,
        partialVisibilityGutter: 20
    }
};

const plans = [
    {
        title: 'Profissional',
        monthlyPrice: 89.99,
        annualPrice: 899.88,
        features: {
            "GESTÃO E CADASTRO": [
                "Gerenciamento simplificado: Cadastre clientes, veículos, produtos e serviços com facilidade.",
                "Consulta de veículos: Obtenha informações completas do veículo cadastrado apenas com a placa.",
                "Acesso para equipe: Permite múltiplos logins simultâneos (até 3 usuários)."
            ],
            "ORDEM DE SERVIÇO E VENDAS": [
                "Compartilhamento via WhatsApp: Envie Ordens de Serviço (O.S.) diretamente para seus clientes.",
                "Aprovação de orçamento online: Seu cliente poderá aprovar remotamente o início do serviço",
                "Função PDV: Ideal para empresas que fazem venda de peças no balcão ou vendas online, sem depender de um serviço ou veículo."
            ],
            "FINANCEIRO E FISCAL": [
                "Cobrança online: Receba pagamentos remotamente via cartão de crédito através de link de pagamento com taxas competitivas.",
                "Controle financeiro: Gerencie contas a pagar, a receber, e tenha o controle de caixa na palma da sua mão."
            ],
            "CRM E RELACIONAMENTO COM CLIENTE": [
                "Lembrete de aniversário de seus clientes",
                "Área do cliente personalizada: Forneça a seu cliente o acompanhamento de serviços em andamento, serviços pendentes com lembretes e histórico completo de revisões já realizadas."
            ],
            "ESTOQUE E COMPRAS": [
                "Gestão de estoque: Cadastro de itens e consumo de peças, influenciando o saldo disponível. Alerta para compras.",
                "Integração com Peça Peças: Acesse um catálogo com mais de 7 milhões de peças automotivas."
            ]
        },
        footnotes: [
            "*Consulte plano com desconto para CPF",
            "**Consulte nosso time para dúvidas e preços relacionados à migração de dados de outros sistemas, configuração de nota fiscal e extração de dados/BI"
        ],
        hasTrial: true
    },
    {
        title: 'Para Empresas',
        monthlyPrice: 239.99,
        annualPrice: 2399.88,
        isEnterprise: true,
        features: {
            "RECURSOS ADICIONAIS DO PLANO EMPRESARIAL": {
                "GESTÃO E CADASTRO": [
                    "Relatórios completos: Visualize o desempenho de clientes, produtos e serviços mais vendidos. E muito mais!",
                    "Gestão de agenda: Veja em formato de calendário tudo o que acontece na sua empresa. Organize suas contas, datas importantes, agende serviços e crie lembretes automáticos.",
                    "Kanban 4K: Monitore e compartilhe o progresso dos serviços com seus funcionários e clientes em uma tela de TV.",
                    "Acesso ampliado para equipes: Permite até 10 usuários cadastrados no sistema e com acesso a área logada (para mais usuários cadastrados, consulte nosso time comercial)"
                ],
                "ORDEM DE SERVIÇO E VENDAS": [
                    "Abertura de OS com checklist completo identificando as principais informações e fotos do veículo recebido"
                ],
                "FINANCEIRO E FISCAL": [
                    "Maquininha de cartão: Enviamos para você uma máquina de cartão com taxas competitivas para sua conveniência (consulte para mais detalhes).",
                    "Emissão de notas fiscais: Nota de produto (NF-e) e nota de serviço (NFS-e) emitidas automaticamente pela plataforma após a criação de uma OS."
                ],
                "CRM E RELACIONAMENTO COM CLIENTE": [
                    "Etiqueta de óleo personalizada: Solicite uma impressora térmica para etiquetas com QR Code para acesso à área do cliente.",
                    "Garage Card exclusivo: Histórico do veículo acessível online para seus clientes, de forma personalizada (consulte valores adicionais)",
                    "Consulte nosso time para ferramentas de CRM, email marketing, sites próprios e integração com Mercado Livre ou criação do seu e-commerce próprio (consulte valores adicionais)"
                ]
            }
        },
        footnotes: [
            "*Consulte nosso time para dúvidas e preços relacionados à migração de dados de outros sistemas, configuração de nota fiscal e extração de dados/BI"
        ]
    }
];

const PricingSection = ({standalone = false}) => {
    const [billingCycle, setBillingCycle] = useState('monthly');

    const calculatePrice = (plan) => {
        if (billingCycle === 'annually') {
            const monthlyPrice = (plan.annualPrice / 12).toFixed(2).replace('.', ',');
            return `R$ ${monthlyPrice}`;
        }
        return `R$ ${plan.monthlyPrice.toFixed(2).replace('.', ',')}`;
    };

    const calculateSavings = (plan) => {
        if (billingCycle === 'annually') {
            const annualCostMonthly = plan.monthlyPrice * 12;
            const savings = (annualCostMonthly - plan.annualPrice).toFixed(2).replace('.', ',');
            return savings;
        }
        return 0;
    };

    const handleStartTrial = (e) => {
        e.preventDefault();
        window.open('https://sistema.garagehero.com.br/registrar', '_blank', 'noopener,noreferrer');
    };

    const renderFeatures = (features, isEnterprise = false) => {
        if (isEnterprise) {
            return Object.entries(features["RECURSOS ADICIONAIS DO PLANO EMPRESARIAL"]).map(([category, features]) => (
                <div key={category} className="mb-6">
                    <h4 className="text-center font-bold text-sm text-gray-900 mb-3">{category}</h4>
                    <ul className="space-y-3">
                        {features.map((feature, i) => (
                            <li key={i} className="flex gap-2">
                                <CheckCircle className="h-5 w-5 flex-shrink-0 text-green-500"/>
                                <span className="text-sm text-gray-600">{feature}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            ));
        }

        return Object.entries(features).map(([category, featureList]) => (
            <div key={category} className="mb-6">
                <h4 className="text-center font-bold text-sm text-gray-900 mb-3">{category}</h4>
                <ul className="space-y-3">
                    {featureList.map((feature, i) => (
                        <li key={i} className="flex gap-2">
                            <CheckCircle className="h-5 w-5 flex-shrink-0 text-green-500"/>
                            <span className="text-sm text-gray-600">{feature}</span>
                        </li>
                    ))}
                </ul>
            </div>
        ));
    };

    const renderCard = (plan, index) => (
        <Card key={index} className={`relative transition-all duration-300 mx-2 h-full ${
            plan.isEnterprise ? 'border-blue-500 shadow-lg' : 'hover:shadow-md'
        }`}>
            {plan.isEnterprise && (
                <div className="absolute top-1 left-1/2 transform -translate-x-1/2">
                    <span className="inline-flex items-center gap-1 bg-blue-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                        <Star size={14} className="fill-current"/> Plano Completo
                    </span>
                </div>
            )}

            <CardHeader className="text-center pt-8">
                <h3 className="text-2xl font-bold mb-2">{plan.title}</h3>
                <div className="mb-4">
                    <div className="text-4xl font-bold text-blue-600">
                        {calculatePrice(plan)}
                        <span className="text-base font-normal text-gray-600">/mês</span>
                    </div>
                    {billingCycle === 'annually' && (
                        <div className="text-sm text-gray-500 mt-1">
                            <span className="line-through">
                                R$ {plan.monthlyPrice.toFixed(2).replace('.', ',')}
                            </span>
                            <span className="text-green-600 ml-2">
                                Economia de R$ {calculateSavings(plan)}
                            </span>
                            <div className="mt-1 font-medium">
                                Total para pagamento à vista: R$ {plan.annualPrice.toFixed(2).replace('.', ',')}
                            </div>
                        </div>
                    )}
                </div>
                {plan.hasTrial && (
                    <div className="relative py-2">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-200"></div>
                        </div>
                        <div className="relative flex justify-center">
                            <span className="bg-white px-4 text-sm font-medium text-blue-600">
                                TESTE GRÁTIS POR 14 DIAS
                            </span>
                        </div>
                    </div>
                )}
            </CardHeader>

            <CardContent>
                {plan.isEnterprise && (
                    <div className="mb-8 p-4 bg-blue-50 rounded-lg border border-blue-100">
                        <h4 className="text-center font-bold text-blue-800 mb-2">INCLUI TODAS AS FUNCIONALIDADES DO
                            PLANO PROFISSIONAL</h4>
                        <p className="text-center text-blue-600 text-sm">
                            Todas as funcionalidades essenciais do plano Profissional + recursos exclusivos abaixo
                        </p>
                    </div>
                )}

                {renderFeatures(plan.features, plan.isEnterprise)}
            </CardContent>

            <CardFooter className="flex flex-col gap-4">
                <Button
                    className={`w-full py-6 text-lg font-semibold text-white ${
                        plan.isEnterprise
                            ? 'bg-blue-600 hover:bg-blue-700'
                            : 'bg-blue-500 hover:bg-blue-600'
                    }`}
                    onClick={handleStartTrial}
                >
                    {plan.hasTrial ? 'Começar Teste Grátis' : 'Cadastre-se Agora'}
                </Button>

                {plan.footnotes && (
                    <div className="text-xs text-gray-500">
                        {plan.footnotes.map((note, i) => (
                            <p key={i} className="mb-1">{note}</p>
                        ))}
                    </div>
                )}
            </CardFooter>
        </Card>
    );

    const renderPlans = () => (
        <div className="max-w-6xl mx-auto relative">
            <Carousel
                responsive={responsive}
                infinite={false}
                arrows={true}
                autoPlay={false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="transform 300ms ease-in-out"
                transitionDuration={300}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customLeftArrow={
                    <button className="absolute left-0 z-10 p-2 -ml-4 bg-white rounded-full shadow-md cursor-pointer hover:bg-gray-100 focus:outline-none">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                            <path d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                }
                customRightArrow={
                    <button className="absolute right-0 z-10 p-2 -mr-4 bg-white rounded-full shadow-md cursor-pointer hover:bg-gray-100 focus:outline-none">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                            <path d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                }
            >
                {plans.map((plan, index) => renderCard(plan, index))}
            </Carousel>
        </div>
    );

    return (
        <section className={`py-12 ${standalone ? 'bg-gray-50' : ''}`}>
            <div className="container mx-auto px-4">
                <div className="text-center mb-12">
                    <h2 className="text-3xl md:text-4xl font-bold mb-4 flex flex-wrap justify-center items-center gap-2">
                        <span>ESCOLHA O</span>
                        <span className="inline-flex flex-wrap gap-1">
                            <span className="bg-blue-500 text-white px-3 py-1 rounded-lg">MELHOR PLANO</span>
                        </span>
                    </h2>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                        Compare os planos e encontre o ideal para o seu negócio
                    </p>

                    <div className="flex items-center justify-center gap-4 mt-8">
                        <span className={`font-medium ${billingCycle === 'monthly' ? 'text-blue-600' : 'text-gray-600'}`}>
                            Mensal
                        </span>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                checked={billingCycle === 'annually'}
                                onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annually' : 'monthly')}
                                className="sr-only peer"
                            />
                            <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-blue-600"></div>
                        </label>
                        <span className={`font-medium ${billingCycle === 'annually' ? 'text-blue-600' : 'text-gray-600'}`}>
                            Anual
                            {billingCycle === 'annually' && (
                                <span className="ml-2 inline-flex items-center text-sm bg-green-100 text-green-800 px-2 py-0.5 rounded">
                                    Economize até 20%
                                </span>
                            )}
                        </span>
                    </div>
                </div>

                <div className="hidden lg:block text-center mb-8">
                    <div className="inline-flex items-center gap-2 text-blue-600">
                        <Plus size={16} className="text-blue-500" />
                        <span className="text-sm font-medium">Recursos exclusivos do plano Para Empresas</span>
                    </div>
                </div>

                {renderPlans()}
            </div>
        </section>
    );
};

export default PricingSection;
