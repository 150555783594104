import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './TestimonialsSection.css';

const testimonials = [
  {
    image: `${process.env.PUBLIC_URL}/testemonials/Depoimento-1.jpg`,
    quote: 'Antes da GarageHero, nossa gestão era uma bagunça. Muitas vezes perdíamos até OS de clientes, por falta da gestão. Em abril de 2022 conhecemos a GarageHero através do Nilson da oficina PowerClass, o sistema de cartão aos clientes, chamou nossa atenção, onde achamos uma oportunidade de trazer um sistema, com uma gestão fácil e um diferencial a nossa empresa. Hoje, somos imensamente gratos por ter fechado com a empresa, uma empresa que busca sempre melhorar, ótimo atendimento dos colaboradores, prezam pelas ideais dos clientes, o que achei muito bacana e acima de tudo a gestão do sistema é incrível. E tem um custo beneficio excelente. Nós da Parise Auto Center recomendamos e temos orgulho por fazer parte da cartela de clientes da GarageHero.',
    clientName: 'Parise Car Service'
  },
  {
    image: `${process.env.PUBLIC_URL}/testemonials/image-6.png`,
    quote: 'Sou fundador da E-Scuderia e sempre tive muita dificuldade em gerenciar o pátio. Porém quando conheci o GarageHero evolui para outro nível na gestão da oficina. Não tenho palavra para descrever a facilidade no uso e ter uma proximidade a mais com meus clientes através do QRCode.',
    clientName: 'E-Scuderia Car Service'
  },
  {
    image: `${process.env.PUBLIC_URL}/testemonials/image-7.png`,
    quote: 'Procurávamos um sistema bem intuitivo que nos atendesse e depois de uma pesquisa encontramos o GarageHero que está nos ajudando bastante com a organização de ordens de serviço, contas a pagar e outras necessidades. Resumindo é um sistema bem completo e hoje temos controle total do que passa na oficina.',
    clientName: 'Box 12 Automotivo'
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1070 },
    items: 1,
    partialVisibilityGutter: 30,
  },
  desktop: {
    breakpoint: { max: 1070, min: 768 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const TestimonialsSection = () => {
  return (
    <section className="py-10" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Fundo-Azul.png)`, backgroundSize: 'cover' }}>
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-6">
          OPINIÃO DE NOSSOS <span className="bg-blue-500 text-white px-3 py-1 rounded-lg"> CLIENTES </span>
        </h2>
        <div className="flex flex-col xl:flex-row items-center">
          <div className="flex-shrink-0 w-full xl:w-1/3 text-center xl:text-left mb-8 xl:mb-0">
            <div className="p-1 md:p-6 bg-transparent text-white rounded-lg flex flex-row gap-3 items-center xl:items-start">
              <img src={`${process.env.PUBLIC_URL}/Aspas.png`} alt="Aspas" className="h-10" />
              <div>
                <blockquote className="mb-4 text-2xl md:text-4xl">CLIENTES REAIS</blockquote>
                <p className="text-1xl md:text-2xl">RESULTADOS INCRÍVEIS</p>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-2/3">
            <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={8000} keyBoardControl={true} showDots={true}>
              {testimonials.map((testimonial, index) => (
                <div key={index} className="p-4">
                  <div className="testimonial-card p-6 rounded-lg flex flex-col xl:flex-row items-center">
                    <div className="flex-shrink-0 mb-4 xl:mb-0 xl:mr-6 max-w-xs w-full xl:w-auto">
                      <img src={testimonial.image} alt={testimonial.clientName} className="rounded-lg w-[320px] h-[373px] max-w-[320px] max-h-[373px] object-cover" />
                    </div>
                    <div>
                      <blockquote className="text-white mb-4">"{testimonial.quote}"</blockquote>
                      <p className="text-lg font-semibold text-white">{testimonial.clientName}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
