// src/pages/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto py-10 px-4">
      <h1 className="text-3xl font-bold mb-4">Política de Privacidade e Uso de Dados</h1>

      <p className="mb-4">
        Prezado USUÁRIO,
      </p>
      <p className="mb-4">
        Obrigado por investir seu tempo para ler este documento. As informações a seguir o ajudarão a entender e operar em nossas ferramentas. É muito importante que conheça e compreenda as nossas políticas, cláusulas e condições de uso do serviço, bem como os deveres e responsabilidades que viabilizam a contratação e as restrições de direito, aqui destacadas.
      </p>

      <h2 className="text-2xl font-bold mb-4">Políticas de Privacidade e Uso de Dados</h2>
      <p className="mb-4">
        Esta Política de Privacidade e Uso de Dados aplicam-se ao uso dos serviços oferecidos pelo PECA PECAS SERVICOS DE INFORMACOES LTDA, empresa devidamente inscrita no CNPJ/MF sob o n° 67.351.114/0001-57, situada na Rua dos bandeirantes, 82 cj 52, Bom Retiro, São Paulo/SP, doravante nominada LICENCIANTE, por meio dos "Sites" www.pecapecas.com.br, www.motorhero.com.br, www.pecaja.com.br, www.garagehero.com.br e www.pecaepague.com.br ("Sites").
      </p>
      <p className="mb-4">
        Qualquer pessoa, doravante nominada "USUÁRIO" ou no plural "USUÁRIOS", que pretenda utilizar os serviços do LICENCIANTE deverá aceitar a Política de Privacidade e Uso de Dados e todos os demais princípios que o regem.
      </p>
      <p className="mb-4">
        A aceitação desta Política de Privacidade e Uso de Dados é absolutamente indispensável à utilização dos Sites e serviços prestados pelo LICENCIANTE.
      </p>
      <p className="mb-4">
        O USUÁRIO deverá ler, certificar-se de haver entendido e aceitar todas as condições estabelecidas nesta "Política de Privacidade e Uso de Dados" e no "Termo de Adesão e Condições Gerais" e assim como nos demais documentos a eles incorporados por referência, antes de seu cadastro como USUÁRIO do LICENCIANTE.
      </p>

      <h2 className="text-2xl font-bold mb-4">1. INTEGRAÇÃO DE DADOS ENTRE SERVIÇOS E DECLARAÇÃO DE PRIVACIDADE</h2>
      <p className="mb-4">
        O objetivo do LICENCIANTE é de facilitar e beneficiar USUÁRIOS no setor automotivo e de autopeças. Para isso, criou um ecossistema de serviços integrados por meio de suas plataformas ("Sites"). As plataformas compõem um arranjo fechado de troca de informações entre si (interfuncionalidades) e é necessária e indispensável para a prestação dos serviços oferecidos pelo LICENCIANTE.
      </p>
      <p className="mb-4">
        Para tanto, como não há o consentimento do USUÁRIO para a criação de múltiplas contas (logins) em todos os "Sites" as informações só serão compostas e processadas de forma integrada apenas após o cadastro do USUÁRIO em um ou mais "sites". Neste caso a informação e dados do USUÁRIO serão compartilhadas entre as plataformas ("sites") do LICENCIANTE.
      </p>
      <p className="mb-4">
        Esta Política de Privacidade e Uso de Dados se aplica a todos os serviços e operações de processamento de dados do LICENCIANTE.
      </p>

      <h2 className="text-2xl font-bold mb-4">2. QUEM É O RESPONSÁVEL PELO PROCESSAMENTO DAS INFORMAÇÕES PESSOAIS DOS USUÁRIOS?</h2>
      <p className="mb-4">
        O LICENCIANTE é responsável pelo processamento dos dados dos USUÁRIOS e visitantes de suas plataformas ("Sites). Para isso, determina-se os propósitos ou usos para os quais as informações pessoais serão utilizadas e os meios que serão utilizados para tal processamento.
      </p>

      <h2 className="text-2xl font-bold mb-4">3. QUAIS INFORMAÇÕES O LICENCIANTE COLETA E PROCESSA?</h2>
      <p className="mb-4">
        O LICENCIANTE coleta suas informações pessoais e empresariais para que os USUÁRIOS possam utilizar os serviços e para que o LICENCIANTE possa aprimorá-los continuamente.
      </p>
      <p className="mb-4">
        Em alguns casos, as informações serão fornecidas pelo próprio USUÁRIO, ao registrar-se nos 'sites' ou ao fornecer informações ao utilizar algum de nossos serviços.
      </p>
      <p className="mb-4">
        A informação de dados pessoais e empresariais trata-se de um requisito essencial para que possa contratar e/ou ter qualquer tipo de relacionamento com o LICENCIANTE e, caso não forneça essas informações, não seremos capazes fornecer nossos serviços aos USUÁRIOS. A imprecisão ou inexatidão dos dados pessoais/empresariais que você fornecer poderá ocasionar a suspensão dos serviços. Da mesma forma, o LICENCIANTE poderá suspender ou desabilitar permanentemente os USUÁRIOS que violarem este documento ("Política de Privacidade e Uso de Dados").
      </p>
      <p className="mb-4">
        Veja abaixo os tipos de dados que são coletados pelo LICENCIANTE:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>Nome pessoal ou empresarial.</li>
        <li>Número de documento ou identificação válida (CPF/CNPJ).</li>
        <li>Informações de contato (como número de telefone, endereço, endereço de e-mail).</li>
        <li>Detalhes da conta bancária.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">Informações que coletamos automaticamente, independentemente se está registrado ou não:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Informações dos dispositivos ou computadores a partir dos quais acessa a plataforma do LICENCIANTE e outros dados capturados automaticamente (como o tipo ou versão do navegador ou o sistema operacional, configurações e parâmetros).</li>
        <li>Endereço IP da internet que utiliza ao se conectar aos nossos serviços ou ao navegar em nossos sites.</li>
        <li>Informações sobre transações e movimentos nas plataformas do LICENCIANTE (compras, pagamentos, devoluções, perguntas, saques, transferências, movimentos do cartão pré-pago, créditos recebidos, adiantamentos em dinheiro, reclamações, faturamento, dados fiscais, senha, contas bancárias e mensagens internas).</li>
        <li>Informações sobre a atividade dos USUÁRIOS e visitantes em nossos sites e aplicativos. Essas informações podem incluir o URL de origem (estejam ou não em nosso site), quais URLs acessam em seguida (estejam ou não em nosso site). Também poderão ser armazenadas e retidas as páginas visitadas, as interações com as referidas páginas, pesquisas realizadas, publicações, compras ou vendas, classificações e respostas, reclamações feitas e recebidas, mensagens nos fóruns, entre outras informações.</li>
        <li>Informações sobre a localização (geolocalização) de USUÁRIOS, que podem ser utilizadas para oferecer descontos, recalls de produtos, localização de lojas com QR ativado para pagamento, entre outras.</li>
        <li>Links e vínculos de contas entre USUÁRIOS, para ações de prevenção de fraudes.</li>
        <li>Trocas de mensagens de dúvidas, problemas e reclamações.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">Informações que coletamos de outras fontes:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Informações coletadas para fins de prevenção de fraudes e cumprimento dos regimes de informações (listas PEPs, OFAC etc.).</li>
        <li>Informações de crédito obtidas a partir de bases de risco de crédito e/ou fontes de acesso público, de acordo com a lei aplicável.</li>
        <li>Dados utilizados para validação de identidade, obtidos a partir de fontes seguras e confiáveis, como órgãos públicos, prestadores de serviços ou parceiros de negócios com os quais trabalhamos.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">4. O QUE O LICENCIANTE FAZ COM INFORMAÇÕES PESSOAIS?</h2>
      <p className="mb-4">
        A coleta e o processamento de informações pessoais e empresariais dos USUÁRIOS permite ao LICENCIANTE prestar um serviço com maior qualidade de disponibilidade técnica e com transações com rapidez e segurança, além de oferecer funcionalidades que melhor se adaptam às necessidades dos USUÁRIOS. Exceto nos casos proibidos pelos regulamentos aplicáveis, o LICENCIANTE poderá utilizar as informações pessoais e empresariais para os seguintes fins:
      </p>

      <h3 className="text-xl font-semibold mb-4">i. Fornecer os produtos, serviços e/ou benefícios oferecidos pelas plataformas do LICENCIANTE.</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Identificar e contatar USUÁRIOS.</li>
        <li>Registrar USUÁRIOS em nossos sistemas.</li>
        <li>Verificar identidade de USUÁRIOS em conformidade com os requisitos legais.</li>
        <li>Fornecer os produtos, serviços e/ou benefícios que USUÁRIOS solicitam.</li>
        <li>Facilitar o contato direto de USUÁRIOS com vendedores/anunciantes ou o comprador para os fins da transação que deseja realizar.</li>
        <li>Elaborar e manter um registro das operações realizadas por USUÁRIOS, bem como manter painel informativo para ciência e realização do acompanhamento adequado de transações comerciais.</li>
        <li>Colocar à disposição de USUÁRIOS a plataforma de comércio eletrônico e o sistema de pagamento online conhecido como PEÇA&PAGUE.</li>
        <li>Gerenciar os serviços e produtos do LICENCIANTE, incluindo o serviço de processamento de pagamentos e a conta digital.</li>
        <li>Atender comentários, reclamações e sugestões dos USUÁRIOS, além de fornecer suporte.</li>
        <li>Elaborar perfis para fins de análise de crédito.</li>
        <li>Oferecer e gerenciar os produtos de crédito que solicitar ou contratar conosco.</li>
        <li>Cobrança judicial e/ou extrajudicial.</li>
        <li>Facilitar o envio de produtos anunciados em sites, aplicativos e outras plataformas de comércio eletrônico operadas pelo LICENCIANTE, ou aquelas que utilizem suas tecnologias.</li>
        <li>Oferecer os serviços e funcionalidades que melhor atendam às necessidades de USUÁRIOS e personalizar nossos serviços para tornar a experiências com o LICENCIANTE o mais confortável e transparente possível.</li>
        <li>Permitir a participação de USUÁRIOS em serviço de negociação de lotes, solicitações de ordens de serviço e cotações, caso sejam realizados e aplicáveis aos USUÁRIOS, além de notificar caso seja o vencedor, sempre em conformidade com os regulamentos aplicáveis a tais negociações.</li>
        <li>Contribuir para a segurança das relações, comunicações e transações entre os USUÁRIOS da plataforma.</li>
        <li>Desenvolver um sistema de reputação do USUÁRIO, para benefício dos consumidores.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">ii. Aprimorar serviços, desenvolver novos serviços e oferecer uma melhor experiência com as plataformas do LICENCIANTE.</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Desenvolver estudos internos sobre interesses e comportamentos de USUÁRIOS, para oferecer melhores serviços e produtos, além de realizar estudos estatísticos.</li>
        <li>Traçar perfis analisando diversas variáveis, como conduta ou interações na plataforma, análise e previsão de capacidade econômica, preferências, interesses, histórico de transações, comportamento e localização, entre outras, para melhorar nossas iniciativas comerciais e promocionais, exibir publicidade ou promoções, banners de interesse, notícias, aprimorar nossa oferta de conteúdo e artigos, personalizar o conteúdo, apresentação e serviços.</li>
        <li>Oferecer os serviços e funcionalidades que melhor atendam às necessidades de USUÁRIOS e personalizar serviços para tornar a experiências com o LICENCIANTE o mais confortável e transparente possível.</li>
        <li>Aprimorar iniciativas comerciais e promocionais do LICENCIANTE e analisar as páginas visitadas, as pesquisas realizadas pelos USUÁRIO, para aprimorar oferta de conteúdo e artigos, personalizar o conteúdo, sua apresentação e serviços.</li>
        <li>Fornecer informações através de diferentes canais (por e-mail, mensagens curtas de texto (SMS), mensagens push, ligações telefônicas ou qualquer outro meio) sobre melhorias ou novas funções ou serviços da plataforma.</li>
        <li>Conquistar sua fidelidade através de um programa de benefícios.</li>
        <li>Permitir que terceiros, por meio do uso de APIs públicas, criem aplicativos que utilizem conteúdo da plataforma e interajam com ela para facilitar, aprimorar e/ou aumentar seu uso e suas funcionalidades e os serviços geralmente fornecidos pelo LICENCIANTE.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">iii. Cumprir as obrigações e requisitos legais das autoridades competentes.</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Cumprir os regulamentos aplicáveis ao LICENCIANTE em geral.</li>
        <li>Cumprir normas de Prevenção à Lavagem de Dinheiro e Financiamento ao Terrorismo (Ações de KYC, verificação de identidade contra Pessoas Politicamente Expostas, verificação de perfil e histórico de transações, em conformidade com a regulamentação aplicável sobre prevenção à lavagem de dinheiro, verificação contra listas OFAC e outras).</li>
        <li>Cumprir regimes de informação em geral.</li>
        <li>Cumprir regimes tributários de arrecadação, registro, informações, auditoria e cobrança, em qualquer nível do governo (por exemplo: federal, estadual e municipal).</li>
        <li>Cumprimento dos requisitos de informação das autoridades administrativas ou judiciais competentes.</li>
        <li>Fornecer informações de USUÁRIOS a entidades governamentais com acordos de colaboração para o cumprimento de suas competências.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">iv. Detectar e prevenir fraudes, abusos e crimes relacionados a fim de proteger a segurança de USUÁRIOS e a sustentabilidade das plataformas.</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Fazer com que as plataformas cresçam de forma sustentável e com segurança por meio de ferramentas e ações de prevenção de fraudes e crimes relacionados.</li>
        <li>Treinar o modelo automatizado (algoritmo de detecção e prevenção) de fraudes.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">v. Proteger os direitos de USUÁRIOS, terceiros ou do próprio LICENCIANTE.</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Defender os direitos, ativos tangíveis e intangíveis do LICENCIANTE.</li>
        <li>Reivindicar os direitos do LICENCIANTE em caso de violação de seus Termos e Condições.</li>
        <li>Permitir que outros USUÁRIOS ou terceiros reivindiquem seus direitos.</li>
        <li>Colaborar com os proprietários de propriedade intelectual no exercício de seus direitos.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">vi. Colaborar com a proteção dos direitos de propriedade intelectual, próprios ou de terceiros, investigações de violação de tais direitos.</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Colaborar com as entidades que intervêm na resolução de disputas.</li>
        <li>Colaborar com entidades privadas que representam interesses coletivos com um acordo de colaboração, para facilitar a resolução de disputas.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">vii. Fazer anúncios e contatos publicitários e promocionais.</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Contatar USUÁRIOS por meio de diferentes canais (por e-mail, mensagens curtas de texto (SMS), mensagens push, chamadas telefônicas ou outros meios) para fins publicitários e/ou promocionais de produtos e serviços do LICENCIANTE e/ou de terceiros.</li>
        <li>Realizar qualquer tipo de atividade de marketing, publicidade, prospecção comercial e/ou pesquisa de mercado.</li>
        <li>Algumas das finalidades descritas podem não ser aplicáveis enquanto não utilizar o serviço associado a tais finalidades.</li>
        <li>O uso de dados USUÁRIOS para qualquer outra finalidade que não seja compatível com as descritas será comunicado antes de prosseguirmos com o processamento.</li>
        <li>O LICENCIANTE manterá as informações pessoais de USUÁRIOS pelo tempo que for necessário para cumprir os fins para os quais foram coletadas.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">5. COMO E COM QUEM O LICENCIANTE COMPARTILHA INFORMAÇÕES PESSOAIS DE USUÁRIOS?</h2>
      <p className="mb-4">
        A proteção da privacidade de nossos USUÁRIOS é essencial para a garantia dos serviços prestados pelo LICENCIANTE. Portanto, não existe interesse em venda ou comercialização de informações que identifiquem os USUÁRIOS de ferramentas do LICENCIANTE. Também não é compartilhado ou transferido de qualquer forma informações pessoais de USUÁRIOS para terceiros, exceto conforme indicado abaixo:
      </p>

      <h3 className="text-xl font-semibold mb-4">O LICENCIANTE poderá ceder, transmitir e/ou transferir Informações pessoais de USUÁRIOS para:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>
          <strong>"Prestadores de Serviços":</strong> prestadores de serviços ou empresas de outsourcing são terceiros contratados para atuar em nome do LICENCIANTE para prestar serviços seguindo instruções e de acordo com o estabelecido neste documento ('Política de Privacidade e Uso de Dados'), a fim de ajudar a melhorar ou facilitar as operações através da plataforma do LICENCIANTE, como:
          <ul className="list-disc pl-5 mb-4">
            <li>(a) empresas de transporte, logística, courier e de entrega para o envio dos produtos que comprou;</li>
            <li>(b) meios de pagamento, intermediários na gestão de pagamentos ou seguros, para obtenção do pagamento pelos serviços ou produtos contratados, bem como para fornecer proteção em relação aos produtos adquiridos;</li>
            <li>(c) provedores de sistemas de computador, provedores de serviços em nuvem, provedores de bancos de dados e provedores de serviços tecnológicos em geral;</li>
            <li>(d) call centers ou centros de atendimento ao cliente;</li>
            <li>(e) empresas que gerenciam programas de fidelidade para a concessão de benefícios;</li>
            <li>(f) agências de publicidade ou marketing;</li>
            <li>(g) análise de dados;</li>
            <li>(h) agências de cobrança;</li>
            <li>(i) entidades governamentais ou empresas especializadas em riscos, com o objetivo de verificar suas informações.</li>
          </ul>
        </li>
        <li>
          Esses 'Prestadores de Serviços' acessam apenas as informações estritamente necessárias para a prestação dos serviços acordados e não podem utilizá-los para outros fins que não lhes tenham sido confiados pelo LICENCIANTE.
        </li>
      </ul>

      <ul className="list-disc pl-5 mb-4">
        <li><strong>"Parceiros comerciais":</strong> empresas com atividades financeiras ou comerciais com as quais o LICENCIANTE mantém um relacionamento de colaboração ou parceria, a fim de oferecer promoções, produtos e serviços dessas empresas ou co-branding. Nesses casos, o LICENCIANTE toma todas as medidas cabíveis para garantir o cumprimento dos padrões de confidencialidade e segurança, firmando acordos ou contratos com o objetivo de proteger a privacidade dos dados pessoais dos USUÁRIOS e o cumprimento da legislação aplicável.</li>
        <li><strong>"Contraparte na transação":</strong> outros USUÁRIOS das plataformas do LICENCIANTE, nas condições previstas, a fim de colocá-lo em contato com o vendedor ou comprador para efeitos da transação que deseja realizar.</li>
        <li><strong>"Autoridades públicas":</strong> autoridades administrativas e judiciais que, no exercício de sua competência, exijam informações, mesmo que não haja ordem ou citação executiva ou judicial para esse efeito, para os seguintes fins:
          <ul className="list-disc pl-5 mb-4">
            <li>(a) colaborar na investigação e denunciar fraudes, pirataria, violação de direitos de propriedade intelectual ou qualquer outro ato ilícito, bem como qualquer atividade ou circunstância que possa gerar responsabilidade legal para o LICENCIANTE e/ou aos seus USUÁRIOS;</li>
            <li>(b) resguardar um interesse público, a aplicação ou administração da justiça, o reconhecimento, exercício ou defesa de um direito em um processo judicial ou administrativo e/ou a resolução de disputas; e</li>
            <li>(c) cumprir com qualquer lei, regulamento ou disposição legal aplicável, ou algum mandato de autoridade competente devidamente fundamentado e motivado.</li>
          </ul>
        </li>
        <li><strong>"Intervenientes em Disputas":</strong> autoridades, compositores amigáveis, tribunais ou entidades que intervenham na resolução de disputas, a fim de resolver disputas que possam surgir entre USUÁRIOS ou entre eles e qualquer empresa do grupo corporativo do LICENCIANTE.</li>
        <li><strong>"USUÁRIOS e/ou visitantes em geral":</strong> outros USUÁRIOS e/ou visitantes de nossa plataforma, a fim de fornecer um serviço de qualidade ao consumidor final, fornecendo informações para que possam tomar uma decisão de compra informada. O número de vendas realizadas pelo vendedor será publicado na plataforma, assim como a reputação, atendimento ao cliente, opiniões dos compradores (cujo conteúdo não é de responsabilidade do LICENCIANTE) e pontualidade na entrega dos produtos.</li>
      </ul>

      <p className="mb-4">
        Da mesma forma, o LICENCIANTE poderá divulgar informações pessoais dos USUÁRIOS a critério do próprio USUÁRIO a outros USUÁRIOS dos Sites e/ou Serviços, entidades ou terceiros quando houver motivos suficientes para considerar que sua atividade é suspeita de tentar ou cometer um crime ou tentar prejudicar outras pessoas.
      </p>

      <p className="mb-4">
        Caso o LICENCIANTE decida compartilhar informações pessoais de USUÁRIOS a terceiros que não os mencionados, solicitaremos o consentimento prévio e expresso, desde que não haja autorização ou obrigação legal que permita que seja realizado sem tal consentimento.
      </p>

      <p className="mb-4">
        Da mesma forma, USUÁRIOS darão o consentimento expresso e serão informados para que o LICENCIANTE ceda, transmita ou transfira as informações pessoais de USUÁRIOS aos destinatários descritos nesta 'Política de Privacidade e Uso de Dados'.
      </p>

      <p className="mb-4">
        O LICENCIANTE não será responsável pelo uso indevido de informações pessoais de USUÁRIOS por parte de terceiros se tais terceiros tiverem coletado e/ou processado diretamente informações pessoais dos USUÁRIOS.
      </p>

      <p className="mb-4">
        Por fim, o LICENCIANTE armazena informações pessoais somente pelo tempo que for necessário para cumprir o objetivo para o qual foram coletadas, cumprir requisitos legais ou regulamentares ou durante o prazo de prescrição legal de possíveis responsabilidades legais ou contratuais.
      </p>

      <h2 className="text-2xl font-bold mb-4">6. DECISÕES AUTOMATIZADAS E CRIAÇÕES DE PERFIS</h2>
      <p className="mb-4">
        O LICENCIANTE tem o compromisso de fornecer melhores serviços que simplificam a rotina de trabalho e gestão de negócios de USUÁRIOS.
      </p>
      <p className="mb-4">
        Para isso, o LICENCIANTE recorre a ferramentas que ajudam a ser mais eficientes por meio de técnicas conhecidas como "inteligência artificial", "machine learning" ou "Big Data" que se utilizam para diversos fins, como prevenção de fraudes, análise e previsão da capacidade econômica, personalização de serviços e publicidade, mecanismos automatizados de segurança cibernética ou validação de identidade por meio do reconhecimento de imagens.
      </p>
      <p className="mb-4">
        Decisões automatizadas são aquelas tomadas com base no uso de algoritmos e programas de computador, sem que nenhum ser humano esteja envolvido no processo de tomada de decisão.
      </p>
      <p className="mb-4">
        Por outro lado, a criação de perfis é a avaliação de alguns aspectos pessoais, como interesses, preferências, comportamentos ou localização de USUÁRIOS, realizada através do processamento automatizado das informações pessoais com procedimentos estatísticos. Como resultado desse processamento, o LICENCIANTE pode enviar comunicações personalizadas ou mostrá-las em suas plataformas, que acreditamos ser do interesse de USUÁRIOS. Os USUÁRIOS têm o direito de solicitar a revisão de uma decisão baseada no processamento automatizado de dados.
      </p>

      <h2 className="text-2xl font-bold mb-4">7. CONFIDENCIALIDADE DE INFORMAÇÕES PESSOAIS E RESPONSABILIDADE DO USUÁRIO</h2>
      <p className="mb-4">
        O LICENCIANTE trabalha para proteger a privacidade de informações pessoais de USUÁRIOS. O USUÁRIO será responsável por todos os atos que ocorrerem através do uso de seu login (email, senha, apelido, CNPJ/CPF, entre outros). Se, por algum motivo, o USUÁRIO desconfiar que outra pessoa sabe sua senha, recomendamos que esta seja modificada entrando no menu de navegação em Menu &gt; Dados de Acesso &gt; Alterar Senha de Acesso.
      </p>

      <h2 className="text-2xl font-bold mb-4">8. USO DE INFORMAÇÕES PESSOAIS POR OUTROS USUÁRIOS</h2>
      <p className="mb-4">
        O LICENCIANTE permite que os USUÁRIOS tenham acesso ilimitado a determinados dados (como nome, apelido, e-mail, outras informações de contato e informações de cobrança e envio) de outros USUÁRIOS para facilitar a interação entre eles.
      </p>
      <p className="mb-4">
        Quando um USUÁRIO vendedor recebe informações pessoais de seus compradores, ele se torna responsável pelo processamento desses dados e adquire as obrigações que isso implica. Portanto, não é permitido utilizar os dados para uma finalidade distinta ou incompatível com a que originou a coleta: concluir uma transação na plataforma do LICENCIANTE. Por esse motivo, eles podem utilizar apenas as informações pessoais de outros USUÁRIOS obtidas no site para:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>(a) fins relacionados a transações na plataforma do LICENCIANTE,</li>
        <li>(b) utilizar serviços oferecidos pelo LICENCIANTE (por exemplo: depósito, seguro, envio ou transporte e reclamações de fraude) e</li>
        <li>(c) qualquer outra finalidade para a qual o USUÁRIO correspondente consinta expressamente depois que as informações exigidas por lei forem previamente comunicadas.</li>
      </ul>

      <p className="mb-4">
        O LICENCIANTE não aceita condutas consideradas como "spam". É absolutamente proibido o envio indiscriminado de mensagens de qualquer natureza entre os USUÁRIOS do LICENCIANTE.
      </p>
      <p className="mb-4">
        O LICENCIANTE não controla, armazena ou tem acesso às mensagens e conteúdos enviados pelos USUÁRIOS por outros meios que não os 'Sites' e, portanto, não assume nenhuma responsabilidade por qualquer dano que possa ser causado por seu uso. As negociações realizadas pelos USUÁRIOS através desses meios são de responsabilidade exclusiva dos USUÁRIOS.
      </p>
      <p className="mb-4">
        O LICENCIANTE não é responsável pelo uso que qualquer USUÁRIO ou outra pessoa possa fazer das informações publicadas nos sites. Os USUÁRIOS concordam que o LICENCIANTE não será responsável por perdas ou danos que possam ser gerados como resultado de negociações entre os USUÁRIOS. Consequentemente, o USUÁRIO isenta o LICENCIANTE de qualquer tipo de responsabilidade caso tenha qualquer conflito com um ou mais USUÁRIOS.
      </p>

      <h2 className="text-2xl font-bold mb-4">9. LINKS PARA OUTROS SITES</h2>
      <p className="mb-4">
        Por meio dos 'Sites', o LICENCIANTE pode incluir links para sites de terceiros, o que não indica que pertençam ou sejam operados pelo LICENCIANTE. O processamento das informações pessoais de USUÁRIOS por terceiros responsáveis pelos sites mencionados acima não é abrangido por este documento ('Política de Privacidade e Uso de Dados'). Da mesma forma, os USUÁRIOS reconhecem e aceitam que o LICENCIANTE não tem controle sobre esses sites e que ele não é e nem será responsável pelo conteúdo ou pelos serviços fornecidos por tais sites, nem pela forma como processam informações pessoais de USUÁRIOS, dado que USUÁRIOS acessam esses sites por conta e risco.
      </p>

      <h2 className="text-2xl font-bold mb-4">10. COMO PODERÁ EXERCER SEUS DIREITOS DE CONTROLE DAS SUAS INFORMAÇÕES PESSOAIS?</h2>
      <p className="mb-4">
        Os regulamentos aplicáveis concedem certos direitos sobre suas informações pessoais de USUÁRIOS, como por exemplo:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>(i) acesso;</li>
        <li>(ii) atualização;</li>
        <li>(iii) retificação;</li>
        <li>(iv) cessação do envio de anúncios, ofertas e promoções;</li>
        <li>(v) exclusão;</li>
        <li>(vi) revogação do consentimento; e</li>
        <li>(vii) confidencialidade.</li>
      </ul>
      <p className="mb-4">
        USUÁRIOS podem fazer perguntas e/ou solicitações com relação ao uso de dados pessoais através de nossos canais de atendimento.
      </p>
      <p className="mb-4">
        Em certos casos, o LICENCIANTE manterá em arquivos digitalizados as informações pessoais de USUÁRIOS que tenha pedido pela exclusão a fim de cumprir os propósitos descritos neste documento ou quando houver um dever contratual ou legal de manter suas informações pessoais de USUÁRIOS. Uma vez cumprido tal propósito ou extinta a obrigação contratual ou legal, o LICENCIANTE procederá à exclusão de informações pessoais de USUÁRIOS.
      </p>

      <p className="mb-4">
        Os regulamentos aplicáveis concedem certos direitos sobre informações pessoais de USUÁRIOS:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>(i) confirmação da existência do processamento de informações pessoais de USUÁRIOS;</li>
        <li>(ii) acesso;</li>
        <li>(iii) correção de informações pessoais incompletas, imprecisas ou desatualizadas;</li>
        <li>(iv) anonimização, bloqueio ou exclusão de informações pessoais desnecessárias, excessivas ou processadas em violação da legislação aplicável;</li>
        <li>(v) portabilidade das informações pessoais;</li>
        <li>(vi) exclusão das informações pessoais quando não forem mais necessárias para os fins para os quais foram coletadas, exceto no caso de conservação necessária para:
          <ul className="list-disc pl-5 mb-4">
            <li>(a) o cumprimento de uma obrigação legal ou regulamentar;</li>
            <li>(b) a transferência para terceiros por qualquer outro motivo autorizado por lei ou qualquer regulamento que não seja a lei ou regulamento que regula a proteção de dados pessoais no Brasil, incluindo a Lei Brasileira de Proteção de Dados Pessoais - LGPD (Lei n° 13.709/18 ); ou</li>
            <li>(c) o uso exclusivo do controlador de forma anônima;</li>
          </ul>
        </li>
        <li>(vii) informações sobre as entidades públicas e privadas com as quais o LICENCIANTE compartilhou informações pessoais de USUÁRIOS;</li>
        <li>(viii) informações sobre a possibilidade de não dar consentimento e sobre as consequências de recusa de USUÁRIOS;</li>
        <li>(ix) revogação do consentimento;</li>
        <li>(x) fazer reclamação perante a Autoridade Nacional de Proteção de Dados Pessoais relacionada ao processamento de informações pessoais de USUÁRIO; e</li>
        <li>(xi) opor-se ao processamento de informações pessoais de USUÁRIOS devido ao não cumprimento da legislação aplicável.</li>
      </ul>

      <p className="mb-4">
        Sem prejuízo do disposto neste documento ('Política de Privacidade e Uso de Dados'), USUÁRIOS poderão também realizar consultas e/ou exercer os direitos de acesso, retificação e exclusão de informações pessoais por correio postal para nosso endereço presente no cadastro nacional de pessoa jurídica (cartão CNPJ) sob número 67.351.114/0001-57 (PECA PECAS SERVICOS DE INFORMACOES LTDA).
      </p>

      <h2 className="text-2xl font-bold mb-4">11. PUBLICIDADE, COOKIES E OUTRAS TECNOLOGIAS</h2>
      <p className="mb-4">
        O USUÁRIO reconhece e aceita expressamente que o LICENCIANTE poderá utilizar um sistema de monitoramento de comportamento através do uso de "cookies", "web beacons" e/ou outras tecnologias de monitoramento semelhantes.
      </p>
      <p className="mb-4">
        Essas tecnologias são utilizadas para conhecer os interesses e o comportamento daqueles que visitam ou são USUÁRIOS do site e, dessa forma, oferecer um serviço melhor ou fornecer informações relacionadas. O LICENCIANTE também utiliza informações obtidas por meio de cookies para analisar as páginas navegadas pelo visitante ou USUÁRIOS, as pesquisas realizadas, aprimorar iniciativas comerciais e promocionais, exibir publicidade ou promoções, banners de interesse, notícias sobre o LICENCIANTE, aperfeiçoar oferta de conteúdo e artigos, personalizar os referidos conteúdos, apresentações e serviços, bem como promover e fazer cumprir as regras e a segurança dos 'Sites'; também utilizamos para que USUÁRIOS não precisem digitar login/senha com tanta frequência durante uma sessão de navegação, como também para contabilizar e corroborar os registros, a atividade do USUÁRIO e outros conceitos de contratos comerciais, sempre visando a instalação de cookies, o benefício do USUÁRIO que o recebe, e que não serão utilizadas para outros fins não relacionados ao LICENCIANTE. Da mesma forma, armazenamos cookies para oferecer uma experiência mais interativa no site, com base nas ações do USUÁRIO.
      </p>
      <p className="mb-4">
        Dados pessoais de USUÁRIOS obtidos por meio dessas tecnologias não serão transferidos a terceiros de forma diferente das descritas nesta 'Política de Privacidade e Uso de Dados'.
      </p>
      <p className="mb-4">
        Além disso, "cookies" ou outros sistemas semelhantes instalados por terceiros podem ser encontrados em determinadas páginas de sites do LICENCIANTE ou utilizados por anunciantes fora do ambiente promovido pelo LICENCIANTE. Estas 'Política de Privacidade e Uso de Dados' cobrem o uso de "cookies" pelo LICENCIANTE e não incluem o uso de "cookies" instalados por terceiros.
      </p>
      <p className="mb-4">
        A instalação, permanência e existência de cookies no computador de USUÁRIOS ou outro dispositivo depende de exclusiva vontade de USUÁRIOS, podendo ser removidos quando desejado. Para saber como remover os cookies do sistema, é necessário verificar a seção Ajuda (Help) do navegador.
      </p>
      <p className="mb-4">
        Nesse sentido, a qualquer momento USUÁRIOS podem excluir os cookies armazenados, configurar o navegador para que solicite aprovação antes de armazenar cookies ou impedir diretamente o armazenamento de cookies. Este procedimento é realizado de forma distinta em diferentes navegadores, sendo necessário fazê-lo em cada navegador que utilizar.
      </p>
      <p className="mb-4">
        Caso desative os cookies, USUÁRIOS devem lembrar de que não será possível utilizar e aproveitar todos os recursos que o LICENCIANTE oferece.
      </p>

      <h2 className="text-2xl font-bold mb-4">12. SEGURANÇA E ARMAZENAMENTO DE INFORMAÇÕES PESSOAIS</h2>
      <p className="mb-4">
        O LICENCIANTE está em conformidade com os regulamentos e padrões do setor em relação às medidas de segurança aplicáveis às informações pessoais de USUÁRIOS.
      </p>
      <p className="mb-4">
        O LICENCIANTE não se responsabiliza por interceptações ilegais ou violação de seus sistemas ou bancos de dados, ou pela utilização por pessoas não autorizadas. O LICENCIANTE também não se responsabiliza pelo uso inadequado das informações obtidas por esses meios.
      </p>

      <h2 className="text-2xl font-bold mb-4">13. ALTERAÇÕES NA DECLARAÇÃO DE PRIVACIDADE</h2>
      <p className="mb-4">
        Caso o LICENCIANTE faça qualquer alteração na forma como as informações pessoais são gerenciadas, notificaremos os USUÁRIOS através de nossos canais habituais, como e-mail ou mensagens através dos aplicativos. Nos termos permitidos pela regulamentação, no caso de USUÁRIOS que não aceitem esses novos termos, o vínculo contratual com o LICENCIANTE será dissolvido e as informações pessoais desse USUÁRIO não serão utilizadas de nenhuma outra forma que não seja a informada no momento da coleta.
      </p>

      <h2 className="text-2xl font-bold mb-4">14. TARIFAS E FATURAMENTO</h2>
      <p className="mb-4">
        O cadastro no LICENCIANTE é gratuito. Todavia, será cobrada uma “TARIFA DE ENRIQUECIMENTO DE DADOS” para informações dos itens (dados, arquivos, imagens e textos) quando o USUÁRIO vendedor solicitar ao LICENCIANTE como parte do serviço prestado anterior a publicação de seu produto. Além disso, será cobrada a “TARIFA DE VENDA” que somente será paga ao LICENCIANTE quando a negociação se concretizar.
      </p>
      <p className="mb-4">
        O USUÁRIO concorda em pagar ao LICENCIANTE os valores correspondentes a qualquer serviço prestado pelo LICENCIANTE em que haja uma tarifa estabelecida. O LICENCIANTE se reserva o direito de modificar, aumentar ou excluir tarifas vigentes a qualquer momento, observado o estipulado na cláusula 4 ou durante promoções e outras formas transitórias de alteração dos preços praticados. Tais alterações não vigorarão em relação às negociações e anúncios já iniciados na data em que tais alterações sejam publicadas. Para estes, o “Termo de Adesão e Condições Gerais” valerão com a redação anterior.
      </p>
      <p className="mb-4">
        Consulte individualmente as taxas e tarifas cobradas pelo serviço prestado, de acordo com cada produto.
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>Consideram-se taxas: Todos as cobranças individuais relacionadas a migração/enriquecimento de dados ou extração de dados, implantação de novos serviços.</li>
        <li>Consideram-se tarifas: Todas as cobranças recorrentes, mensais ou anuais, relacionadas ao uso do serviço liberação do sistema para os usuários.</li>
      </ul>
      <p className="mb-4">
        O LICENCIANTE se reserva o direito de tomar as medidas judiciais e extrajudiciais pertinentes para receber os valores devidos.
      </p>

      <h2 className="text-2xl font-bold mb-4">15. LEGISLAÇÃO APLICÁVEL E FORO DE ELEIÇÃO</h2>
      <p className="mb-4">
        Todos os itens deste "Política de Privacidade e Uso de Dados" de uso são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou qualquer outro questionamento relacionado a esta "Política de Privacidade e Uso de Dados", as partes concordam em se submeter ao Foro da Cidade de São Paulo - SP, com exceção de reclamações apresentadas por USUÁRIO que se enquadrem no conceito legal de consumidores, que poderão submeter tais reclamações ao foro de seu domicílio.
      </p>

      <h2 className="text-2xl font-bold mb-4">16. CONTATO E ATENDIMENTO</h2>
      <p className="mb-4">
        Todas as requisições, correspondências, notificações ou pedidos de informação deverão ser enviadas para o endereço eletrônico do respectivo serviço:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Peça Peças:</strong> contato@pecapecas.com.br</li>
        <li><strong>MotorHero:</strong> contato@motorhero.com.br</li>
        <li><strong>Peça Já:</strong> não aplicável, usar outro email acima com devida identificação do USUÁRIO</li>
        <li><strong>Garage Hero:</strong> não aplicável, usar outro email acima com devida identificação do USUÁRIO</li>
        <li><strong>Peça&Pague:</strong> não aplicável, usar outro email acima com devida identificação do USUÁRIO</li>
      </ul>
    </div>
  );
}

export default PrivacyPolicy;
