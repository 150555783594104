import React from 'react';
import {ArrowRight} from 'lucide-react';

const HeroSection = () => {
    return (<section
            className="relative min-h-[90vh] bg-gradient-to-br from-blue-50 via-white to-blue-50/30 py-8 sm:py-12 md:py-16 lg:py-20 overflow-hidden">
            <div className="absolute inset-0 bg-grid-blue-500/[0.08] bg-grid-8 -skew-y-3 transform opacity-70"></div>

            <div className="absolute inset-0 overflow-hidden pointer-events-none">
                <div
                    className="absolute top-1/4 -left-12 w-40 h-40 bg-blue-100 rounded-full mix-blend-multiply animate-float opacity-40 blur-3xl"></div>
                <div
                    className="absolute bottom-1/4 right-0 w-56 h-56 bg-blue-50 rounded-full mix-blend-multiply animate-float-delayed opacity-40 blur-3xl"></div>
            </div>

            <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 relative">
                <div className="flex flex-col lg:flex-row items-center justify-between gap-12 xl:gap-16">
                    <div className="w-full lg:w-[42%] text-center lg:text-left z-10">
                        <div
                            className="inline-flex items-center gap-2 bg-gradient-to-r from-blue-600/10 to-blue-500/10 text-blue-600 px-6 py-3 rounded-full text-sm font-semibold mb-8 shadow-sm">
                            <span className="flex w-2 h-2">
                                <span
                                    className="animate-ping absolute w-2 h-2 bg-blue-600 rounded-full opacity-75"></span>
                                <span className="relative w-2 h-2 bg-blue-600 rounded-full"></span>
                            </span>
                            Software Líder para Centros Automotivos
                        </div>

                        <h1 className="text-4xl sm:text-5xl xl:text-5xl font-bold text-gray-900 mb-6 leading-tight text-center uppercase">
                            Seu Centro de
                            <br/>
                            <span
                                className="bg-gradient-to-r from-blue-600 to-blue-500 bg-clip-text text-transparent uppercase">
                                Estética Automotiva
                            </span>
                            <br/>
                            3x Mais Lucrativo
                        </h1>


                        <p className="text-gray-600 text-lg sm:text-xl mb-8 max-w-xl mx-auto lg:mx-0">
                            Sistema completo para estética automotiva que aumentou o ticket médio
                            de +560 empresas para R$1.300. Gestão, agendamentos e controle em um só lugar.
                        </p>

                        <div className="flex flex-col sm:flex-row gap-4 mb-12">
                            <button
                                className="group w-full sm:w-auto px-8 py-5 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-xl font-bold text-lg
                                    transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl hover:shadow-blue-200/50 relative overflow-hidden"
                                onClick={() => window.open('https://sistema.garagehero.com.br/registrar', '_blank')}
                                id="hero-button-register"
                            >
                                <div
                                    className="absolute inset-0 w-full h-full bg-gradient-to-r from-blue-600 to-blue-500 transition-opacity group-hover:opacity-90"></div>
                                <div className="relative flex items-center justify-center gap-2">
                                    <span>Começar Grátis</span>
                                    <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform"/>
                                </div>
                                <span className="relative block text-sm font-normal mt-1 text-blue-100">14 dias grátis • Sem cartão</span>
                            </button>

                            <button
                                className="w-full sm:w-auto px-8 py-5 bg-white text-gray-700 rounded-xl font-bold text-lg
                                    border-2 border-gray-100 transition-all duration-300 hover:-translate-y-1
                                    hover:shadow-xl hover:border-gray-200 backdrop-blur-sm"
                                onClick={() => window.open('https://wa.me/5511934099298?text=Ol%C3%A1%2C%20estou%20no%20site%20do%20GarageHero%20e%20desejo%20ajuda', '_blank')}
                                id="hero-button-demo"
                            >
                                <span className="block">Ver Demonstração</span>
                                <span
                                    className="block text-sm font-normal mt-1 text-gray-500">Atendimento Personalizado</span>
                            </button>
                        </div>

                        <div className="relative">
                            <div className="flex flex-nowrap overflow-x-auto sm:flex-row justify-start sm:justify-between items-center
                                    gap-6 py-6 px-6 lg:px-8 bg-white/80 backdrop-blur-sm rounded-2xl shadow-xl 
                                    shadow-blue-100/50 border border-white/50 sm:overflow-x-hidden">
                                {['560+|Empresas Ativas', 'R$1.3k+|Ticket Médio OS', '66.6k+|Ordens de Serviço'].map((metric, idx) => (
                                    <React.Fragment key={idx}>
                                        <div className="flex-none sm:flex-1 text-center min-w-[150px] sm:min-w-0">
                                            <div className="text-2xl lg:text-3xl font-bold bg-gradient-to-r from-blue-600 to-blue-500
                                                    bg-clip-text text-transparent mb-1 whitespace-nowrap">
                                                {metric.split('|')[0]}
                                            </div>
                                            <div className="text-sm text-gray-500 whitespace-nowrap">
                                                {metric.split('|')[1]}
                                            </div>
                                        </div>
                                        {idx < 2 && (<div
                                                className="hidden sm:block h-12 w-px bg-gradient-to-b from-gray-100 to-gray-200"></div>)}
                                    </React.Fragment>))}
                            </div>
                        </div>
                    </div>

                    <div className="w-full lg:w-[58%] hidden sm:block">
                        <div className="relative h-[500px] sm:h-[600px] md:h-[650px] lg:h-[600px] xl:h-[650px]">
                            <div className="absolute top-0 right-0 w-[85%] rounded-2xl shadow-2xl bg-white/80 backdrop-blur-sm p-2
                                          transform hover:-translate-y-2 transition-all duration-500 hover:shadow-blue-200/50">
                                <img src="/mockup/kanban-desktop.png" alt="Kanban View"
                                     className="w-full h-auto rounded-lg"/>
                            </div>

                            <div className="absolute bottom-0 right-4 w-[45%] rounded-xl shadow-lg bg-white/95 backdrop-blur-sm p-2
                                          transform hover:scale-105 transition-all duration-500 z-20 hover:shadow-blue-200/50">
                                <img src="/mockup/calendar-tablet.png" alt="Calendar View"
                                     className="w-full h-auto rounded-lg"/>
                            </div>

                            <div className="absolute bottom-10 left-0 w-[25%] rounded-xl shadow-lg bg-white/95 backdrop-blur-sm p-2
                                          transform hover:scale-105 transition-all duration-500 z-30 hover:shadow-blue-200/50">
                                <img src="/mockup/report-mobile.png" alt="Mobile Report"
                                     className="w-full h-auto rounded-lg"/>
                            </div>

                            <div className="absolute -top-20 -right-20 w-40 h-40 bg-gradient-to-br from-blue-100 to-blue-50
                                          rounded-full opacity-40 blur-3xl animate-pulse"></div>
                            <div className="absolute -bottom-8 left-1/2 w-40 h-40 bg-gradient-to-br from-blue-50 to-white
                                          rounded-full opacity-40 blur-3xl animate-pulse-slow"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};


export default HeroSection;
