import React from 'react';
import {Button} from "./Button";
import {CheckCircle} from 'lucide-react';

const BusinessPlansSection = () => {
    return (
        <section className="py-16 bg-white from-white to-blue-50">
            <div className="container mx-auto px-4">
                <h2 className="text-center text-3xl md:text-4xl font-bold mb-4">
                    <div className="flex flex-col items-center gap-2">
                        <span>PLANOS PARA</span>
                        <div className="flex flex-wrap justify-center gap-2">
                            <span className="bg-blue-500 text-white px-3 py-1 rounded-lg">MÉDIAS E GRANDES</span>
                            <span>EMPRESAS</span>
                        </div>
                    </div>
                </h2>
                <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                    <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
                        <img
                            src={`${process.env.PUBLIC_URL}/1_xJ00_CZHUzn11kkiFixaIQ.png`}
                            alt="Planos para Médias e Grandes Empresas"
                            className="mx-auto rounded-lg"
                        />
                    </div>
                    <div className="w-full md:w-1/2 space-y-6">
                        <ul className="space-y-4">
                            {[
                                'Desenvolvemos projetos especiais, de acordo com a necessidade de cada cliente',
                                'Possibilidade de solução white-label com domínio, cores e especificações próprias do contratante',
                                'Sistemas com multiempresas, como franquias',
                                'Acesso segregado para administrador e lojista',
                                'Disponível acesso a banco de dados, BI e dashboards',
                                'Entre outros benefícios'
                            ].map((item, index) => (
                                <li key={index} className="flex items-start">
                                    <CheckCircle className="text-green-500 mr-2 flex-shrink-0 mt-1" size={20}/>
                                    <span>{item}</span>
                                </li>
                            ))}
                        </ul>
                        <Button
                            variant="outline"
                            className="mt-8 text-blue-500 border-blue-500 hover:bg-blue-50 text-sm md:text-base"
                            onClick={() => window.open("https://wa.me/5511934099298?text=Ol%C3%A1%2C%20estou%20interessado%20nos%20planos%20para%20m%C3%A9dias%20e%20grandes%20empresas", "_blank")}
                        >
                            Fale Conosco para Mais Detalhes
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BusinessPlansSection;